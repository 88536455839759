import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import React, {useEffect} from "react";
import { useState } from "react";
import {exportToOris, getMembersAdmin, importFromOris, resendWelcomeEmail, resyncUserEntries} from "../../dao";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog, faChartSimple, faEnvelope, faTriangleExclamation, faDownload, faUpload, faQuestionCircle, faUsersCog
} from '@fortawesome/free-solid-svg-icons';
import {isMobileDevice} from "../../helpers/Device";
import Card from "react-bootstrap/Card";
import {handleErrorLoading, handleErrorSubmit, setBrowserTabText} from "../../helpers/Functions";
import PageContainer from "../../layout/PageContainer";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import {AdminAccountTypeOptions} from "../../helpers/Constants";
import LoadingCard from "../../components/overlays/LoadingCard";
import FormSelectDict from "../../components/form/FormSelectDict";
import SearchBar from "../../components/form/SearchBar";
import LoadingIcon from "../../components/overlays/LoadingIcon";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {labels} from "../../themeLabels";

const ClubAdminSpecial = () => {
  const [data, setData] = useState({members: [], logged: {}});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [accountType, setAccountType] = useState("*");
  const [resendWelcome, setResendWelcome] = useState(null);
  const [resyncUser, setResyncUser] = useState(null);
  const [syncToOrisUser, setSyncToOrisUser] = useState(null);
  const [syncToOris, setSyncToOris] = useState(0);
  const [syncFromOris, setSyncFromOris] = useState(0);
  const [syncFromResponse, setSyncFromResponse] = useState(null);
  const [syncFromSuccess, setSyncFromSuccess] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setLoading(true);
    getMembersAdmin(accountType, '*')
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {members: [], logged: {}}
        }
        return response.json()
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [accountType]);

  const handleResendWelcomeEmail = () => {
    resendWelcomeEmail(resendWelcome.id)
      .then((response) => {
        if (response.ok)
          setSuccess("Požadavek přijat");
        else
          setError(handleErrorSubmit(response, "Operace se nezdařila"));
        setResendWelcome(null);
      })
  }

  const handleResyncUserEntries = () => {
    resyncUserEntries(resyncUser.id)
      .then((response) => {
        if (response.ok)
          setSuccess("Požadavek přijat");
        else
          setError(handleErrorSubmit(response, "Operace se nezdařila"));
        setResyncUser(null);
      })
  }

  const handleExportToOris = () => {
    const target = syncToOrisUser == null ? null : syncToOrisUser.id;
    exportToOris(target)
      .then((response) => {
        if (response.ok)
          setSuccess("Požadavek přijat");
        else
          setError(handleErrorSubmit(response, "Operace se nezdařila"));
        setSyncToOrisUser(null);
        setSyncToOris(0);
      })
  }

  const handleImportFromOris = () => {
    setIsProcessing(true);
    importFromOris(syncFromOris === 2)
      .then((response) => {
        if (response.ok) {
          response.json().then(
            (result) => {
              setSyncFromResponse(result);
              setSyncFromSuccess("Úspěšně dokončeno");
            });
        }
        else
          setError(handleErrorSubmit(response, "Operace se nezdařila"));
        setSyncFromOris(0);
        setIsProcessing(false);
      })
  }

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />
  if (isProcessing)
    return <LoadingCard text={"Operace probíhá"} />

  setBrowserTabText('Administrace - rozšířená správa účtů')
  return (
    <PageContainer>
      <h1>Administrace - rozšířená správa účtů</h1>
      <Row>
        <Col>
          <Alert variant={"warning"}><FontAwesomeIcon icon={faTriangleExclamation}/> Upozornění - tyto funkce způsobují nevratné změny!</Alert>
        </Col>
      </Row>
      <Row>
        <Col xxl><UserCard/></Col>
        <Col xxl>
          <Row>
            <Col><SettingCard/></Col>
            <Col xs="auto">
              <FormSelectDict label={"Typ účtu"} value={accountType} setValue={setAccountType} options={AdminAccountTypeOptions} className={"mb-2"} controlId={"account"} />
            </Col>
          </Row>
        </Col>
      </Row>
      <MemberTable/>
      <Modal show={resendWelcome != null} onHide={() => {setResendWelcome(null)}}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Znovu odeslat uživateli {resendWelcome?.full_name} email s přihlašovacími údaji?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Uživateli <b>{resendWelcome?.reg_number} – {resendWelcome?.full_name}</b> bude nastaveno náhodné nové heslo a bude mu odeslán email obsahující uživatelské jméno a heslo.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setResendWelcome(null);
          }}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={handleResendWelcomeEmail}>
            Potvrdit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={resyncUser != null} onHide={() => {setResyncUser(null)}}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Synchronizovat historii uživatele {resyncUser?.full_name} z Orisu?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Historie závodů uživatele <b>{resyncUser?.reg_number} – {resyncUser?.full_name}</b> bude importována z Orisu.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setResyncUser(null);
          }}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={handleResyncUserEntries}>
            Potvrdit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={syncToOrisUser != null} onHide={() => {setSyncToOrisUser(null)}}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Synchronizovat data uživatele {syncToOrisUser?.full_name} do Orisu?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Data uživatele <b>{syncToOrisUser?.reg_number} – {syncToOrisUser?.full_name}</b> budou propsána do Orisu.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setSyncToOrisUser(null);
          }}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={handleExportToOris}>
            Potvrdit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={syncToOris !== 0} onHide={() => {setSyncToOris(0)}}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Synchronizovat data uživatelů do Orisu?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Data uživatelů skupin A a B budou propsána do Orisu.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setSyncToOris(0);
          }}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={handleExportToOris}>
            Potvrdit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={syncFromOris !== 0} onHide={() => {setSyncFromOris(0)}}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce/> &nbsp;
          <Modal.Title>Vytvořit {syncFromOris === 2 && "a doplnit "}uživatele z Orisu?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Uživatelé budou vytvořeni ze záznamů v Orisu{syncFromOris === 2 && <>, existující účty budou aktualizovány, dojde k <b style={{color: "red"}}>přepisu</b> jejich nastavení</>}.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setSyncFromOris(0);
          }}>
            Zavřít
          </Button>
          <Button variant="primary" onClick={handleImportFromOris}>
            Potvrdit
          </Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={() => setSuccess("")}/>
      <SuccessAlert title={syncFromSuccess} body={<>vytvořeno: {syncFromResponse?.added}<br/>doplněno: {syncFromResponse?.updated}</>} handleClose={()=> setSyncFromSuccess("")}/>
    </PageContainer>
  );

  function SettingCard() {
    return (
      <Card className="mb-3">
        <Card.Body>
          <Row className="align-items-baseline">
            <Col xs="auto">
              <Link to={`/admin/ucty/role`}><Button className="mb-2"><FontAwesomeIcon icon={faUsersCog} /> Role</Button></Link>
            </Col>
            <Col xs="auto">
              <Link to={`/admin/ucty/clenstvi`}><Button className="mb-2" variant="outline-primary"><FontAwesomeIcon icon={faUsersCog} /> Členství</Button></Link>
            </Col>
            <Col xs="auto">
              <Link to={`/admin/ucty/skupiny`}><Button className="mb-2" variant="secondary"><FontAwesomeIcon icon={faUsersCog} /> Skupiny</Button></Link>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }

  function UserCard() {
    return (
      <Card className="mb-3">
        <Card.Body>
          <Row className="align-items-baseline">
            <Col xs="auto">
              <Button className="mb-2" onClick={() => setSyncFromOris(1)}><FontAwesomeIcon icon={faDownload} /> Vytvořit z Orisu</Button>
            </Col>
            <Col xs="auto">
              <Button className="mb-2" variant="outline-primary" onClick={() => setSyncFromOris(2)}><FontAwesomeIcon icon={faDownload} /> Vytvořit a doplnit z Orisu</Button>
            </Col>
            <Col xs="auto">
              <Button className="mb-2" variant="secondary" onClick={() => setSyncToOris(1)} disabled={!labels.ClubAdminSpecialSyncToOris}><FontAwesomeIcon icon={faUpload} /> Synchronizace do Orisu</Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }

  function MemberTable() {
    const [filteredPeople, setFilteredPeople] = useState(data.members);

    if (loading)
      return <LoadingIcon />;

    const TableRow = ({element}) => {
      return (
        <tr>
          <td><Link to={'/ucet/' + element.id + '/statistiky/'} className="no-decoration">{element.reg_number}</Link></td>
          <td><Link to={'/ucet/' + element.id + '/nastaveni/'} className="no-decoration">{element.full_name}</Link></td>
          {!isMobileDevice() && <td>{element.mail1}</td>}
          <td>{element.account}</td>
          <td>{element.role}</td>
          <td>{element.membership}</td>
          <td className="td-w-icon">
            {((element.account === "A" || element.account === "B") && labels.ClubAdminSpecialSyncToOris) ?
              <FontAwesomeIcon icon={faUpload} className="link-like" onClick={() => {
                setSyncToOrisUser(element)
              }} title="Synchronizovat data uživatele do Orisu"/> :
              <FontAwesomeIcon icon={faUpload} color="gray"/>
            }
          </td>
          <td className="td-w-icon">
            {(element.account !== "C" && element.account !== "D") ?
              <FontAwesomeIcon icon={faDownload} className="link-like" onClick={() => {
                setResyncUser(element)
              }} title="Synchronizovat historii závodů z Orisu"/> :
              <FontAwesomeIcon icon={faDownload} color="gray"/>
            }
          </td>
          <td className="td-w-icon">
            {(element.account !== "C" && element.account !== "D") ?
              <FontAwesomeIcon icon={faEnvelope} className="link-like" onClick={() => {
                setResendWelcome(element)
              }} title="Odeslat uvítací email s resetovaným heslem"/> :
              <FontAwesomeIcon icon={faEnvelope} color="gray"/>
            }
          </td>
        </tr>
      )
    }

    const rows = [];
    for (let i in filteredPeople) {
      const obj = filteredPeople[i];
      rows.push(<TableRow element={obj} key={i}/>);
    }

    return (<>
      <SearchBar data={data.members} setFilteredPeople={setFilteredPeople}/>
      <Table striped bordered hover responsive>
        <thead>
        <tr>
          <th>Reg. <FontAwesomeIcon icon={faChartSimple}/></th>
          <th>Jméno <FontAwesomeIcon icon={faCog}/></th>
          {!isMobileDevice() && <th>Email primární</th>}
          <th title="Typ účtu">T</th>
          <th title="Role">R</th>
          <th title="Členství">Č</th>
          <th title="Synchronizace dat do Orisu">SYN</th>
          <th title="Synchronizace historie z Orisu">HIS</th>
          <th title="Reset hesla">RST</th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
      <p><i>Filtru odpovídá <b>{rows.length}</b> záznamů</i></p>
    </>)
  }
};


export default ClubAdminSpecial;