import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import React from "react";
import {labels} from "../../themeLabels";


const ModalContentAccount = ({options}) => {
  const roleRows = [];
  for (const short in options?.roles) {
    const item = options.roles[short];
    roleRows.push(<tr>
      <td className="td-w-10">{short}</td>
      <td>{item}</td>
    </tr>)
  }
  const membershipRows = [];
  for (const short in options?.memberships) {
    const item = options.memberships[short];
    membershipRows.push(<tr>
      <td className="td-w-10">{short}</td>
      <td>{item}</td>
    </tr>)
  }
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Nápověda</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Modal.Title>Typy účtů</Modal.Title>
        <Table bordered>
          <tbody>
          <tr>
            <td>A</td>
            <td>aktivní účet (propojený s Orisem) s přihlašovacím přístupem do {labels.SystemShortName}u</td>
          </tr>
          <tr>
            <td>B</td>
            <td>aktivní účet (propojený s Orisem) bez přístupu do systému, někomu je třeba udělit přihlašovací práva</td>
          </tr>
          <tr>
            <td>C</td>
            <td>lokální účet {labels.SystemShortName}u, bez přístupu do systému</td>
          </tr>
          <tr>
            <td>D</td>
            <td>deaktivovaný účet {labels.SystemShortName}u, bez přístupu do systému</td>
          </tr>
          <tr>
            <td>E</td>
            <td>externí účet, který udělil přihlašovací práva v Orisu CORE správci, skrz něhož jsou prováděny přihlášky; může se přes {labels.SystemShortName} přihlašovat na závody i akce klubu</td>
          </tr>
          <tr>
            <td>F</td>
            <td>externí účet, s přístupem do {labels.SystemShortName}u, nemůže se přes {labels.SystemShortName} hlásit na závody, nicméně na akce klubu ano</td>
          </tr>
          </tbody>
        </Table>
        <Modal.Title>Role</Modal.Title>
        <Table bordered>
          <tbody>
          {roleRows}
          </tbody>
        </Table>
        <Modal.Title>Členství</Modal.Title>
        <Table bordered>
          <tbody>
          {membershipRows}
          </tbody>
        </Table>
      </Modal.Body>
    </>
  )
}

export default ModalContentAccount;