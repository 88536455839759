import {Link} from "react-router-dom";
import React from "react";

/**
 *
 * @param children content of element
 * @param href link to over element
 * @param hidden link decoration is hidden, but link is injected
 * @param linked link is injected inside CondElem
 * @param suffixIcon FontAwesomeIcon after children
 * @param showIcon showing suffixIcon
 * @returns {Element}
 * @constructor
 */
const CondElem = ({children, href=`#`, hidden=false, linked=true, suffixIcon=undefined, showIcon=false}) => {
  if (!linked || href === `#`)
    return <>{children} {showIcon && suffixIcon}</>
  return <><Link to={href} className={hidden ? "hide-link" : "no-decoration"}>{children}</Link> {showIcon && suffixIcon}</>
}

export default CondElem;