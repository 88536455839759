import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {editEvent} from "../../dao";
import React, { useState, } from 'react';
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {
  DisciplineBasicOptions,
  PunchingOptions,
  TernaryOptionsAccommodation,
  TernaryOptionsTransport
} from "../../helpers/Constants";
import Modal from "react-bootstrap/Modal";
import {handleErrorSubmit} from "../../helpers/Functions";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Table from "react-bootstrap/Table";
import FormField from "../../components/form/FormField";
import FormSelectDict from "../../components/form/FormSelectDict";
import useUserData from "../../helpers/Auth";
import FormCoordinates from "../../components/form/FormCoordinates";
import MapPositionSelect from "../../components/parts/MapPositionSelect";
import FormCheckbox from "../../components/form/FormCheckbox";

const EditTraining = (props) => {
  const { userData, setUserData } = useUserData();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showMap, setShowMap] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  const [title, setTitle] = useState(props.event.title);
  const [date, setDate] = useState(props.event.date);
  const [place, setPlace] = useState(props.event.place);

  const [info, setInfo] = useState(props.event.info);
  const [eventInfo, setEventInfo] = useState(props.event.event_info);
  const [warning, setWarning] = useState(props.event.event_warning);

  const [termFirst, setTermFirst] = useState(props.event.term_first);
  const [allowLate, setAllowLate] = useState(props.event.allow_late);
  const [start, setStart] = useState(props.event.start);
  const [visible, setVisible] = useState(props.event.visible);

  const [categories, setCategories] = useState(props.event.classes);
  const [discipline, setDiscipline] = useState(props.event.discipline);
  const [organiser, setOrganiser] = useState(props.event.organiser);
  const [punching, setPunching] = useState(props.event.punching);

  const [GPSlong, setGPSlong] = useState(props.event?.GPS_longitude !== undefined ? props.event?.GPS_longitude : '');
  const [GPSlat, setGPSlat] = useState(props.event?.GPS_latitude !== undefined ? props.event?.GPS_latitude : '');

  const [transport, setTransport] = useState(props.event.transport);
  const [transportInfo, setTransportInfo] = useState(props.event.transport_info);

  const [accommodation, setAccommodation] = useState(props.event.accommodation);
  const [accommodationInfo, setAccommodationInfo] = useState(props.event.accommodation_info);

  let handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      "title": title,
      "date": date,
      "place" : place,
      "info": info,
      "event_info": eventInfo,
      "event_warning": warning,
      "visible": visible,
      "term_first": termFirst,
      "allow_late" : allowLate,
      "start": start,
      "discipline": discipline,
      "organiser": organiser,
      "punching": punching,
      "classes": categories,
      "transport": transport,
      "transport_info": transportInfo,
      "accommodation": accommodation,
      "accommodation_info": accommodationInfo,
      "GPS_longitude": GPSlong,
      "GPS_latitude": GPSlat
    }
    const res = await editEvent(props.event.id, formData);
    if (res.ok)
      setSuccess("Akce upravena");
    else
      setError(handleErrorSubmit(res, "Nepodařilo se upravit"));
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <FormField label={"Název"} value={title} setValue={setTitle} valueLimit={512} controlId={"title"} />
          </Col>
          <Col md>
            <FormField label={"Datum"} type="date" value={date} setValue={setDate} controlId={"date"} />
          </Col>
          <Col md>
            <FormField label={"Místo"} value={place} setValue={setPlace} valueLimit={512} controlId={"place"} />
          </Col>
          <hr/>
        </Row>
        <Row>
          <Col>
            <FormField label={"Info text"} value={info} setValue={setInfo} valueLimit={2048} controlId={"info"} />
          </Col>
          <Col md>
            <FormField label={"Info hláška"} value={eventInfo} setValue={setEventInfo} valueLimit={2048} controlId={"eventInfo"} />
          </Col>
          <Col md>
            <FormField label={"Varovná hláška"} value={warning} setValue={setWarning} valueLimit={2048} controlId={"eventWarning"} />
          </Col>
          <hr />
        </Row>
        <Row>
          <Col>
            <FormField label={"Start (hh:mm:ss)"} value={start} setValue={setStart} valueLimit={8} digits={"none"} controlId={"start"} />
          </Col>
          <Col md>
            <FormField label={"Termín přihlášek"} type={"datetime-local"} value={termFirst} setValue={setTermFirst} controlId={"term"} />
          </Col>
          <Col>
            {userData.administrate && <FormCheckbox label={"Přihlášky trenérů po termínu"} value={allowLate} setValue={setAllowLate} controlId={"allowLate"}/>}
            <FormCheckbox label={"Viditelná pro běžné uživatele"} value={visible} setValue={setVisible} controlId={"visible"}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormSelectDict label={"Disciplína"} value={discipline} setValue={setDiscipline} options={DisciplineBasicOptions} controlId={"discipline"} />
          </Col>
          <Col md>
            <FormField label={"Pořadatel"} value={organiser} setValue={setOrganiser} valueLimit={128} controlId={"organiser"} />
          </Col>
          <Col md>
            <FormSelectDict label={"Ražení"} value={punching} setValue={setPunching} options={PunchingOptions} controlId={"punching"} />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <FormField label={<>Kategorie (odděleny středníkem ;) <FontAwesomeIcon icon={faQuestionCircle} onClick={() => setShowHelp(true)} className="pointing"/> </>} value={categories} setValue={setCategories} valueLimit={500} controlId={"categories"} />
          </Col>
          <Col md={4}>
            <FormCoordinates GPSlat={GPSlat} GPSlong={GPSlong} setGPSlat={setGPSlat} setGPSlong={setGPSlong} setShowMap={setShowMap}/>
          </Col>
          <hr/>
        </Row>
        <Row>
          <Col>
            <FormSelectDict label={"Klubová doprava"} value={transport} setValue={setTransport} options={TernaryOptionsTransport} controlId={"transport"} />
          </Col>
          <Col md={8}>
            <FormField label={"Info o dopravě"} value={transportInfo} setValue={setTransportInfo} disabled={transport === "-1"}  valueLimit={128} controlId={"transportInfo"} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormSelectDict label={"Klubové ubytování"} value={accommodation} setValue={setAccommodation} options={TernaryOptionsAccommodation} controlId={"accommodation"} />
          </Col>
          <Col md={8}>
            <FormField label={"Info k ubytování"} value={accommodationInfo} setValue={setAccommodationInfo} disabled={accommodation === "-1"} valueLimit={128} controlId={"accommodationInfo"} />
          </Col>
        </Row>
        <Row>
          <Col className="col-md-12 text-end">
            <Button variant="primary" type="submit">Upravit</Button>
          </Col>
        </Row>
        <br/>
      </Form>
      <Modal show={showMap} onHide={() => setShowMap(false)}>
        <Modal.Header closeButton>
          <Modal.Body>Vyber místo kliknutím do mapy</Modal.Body>
        </Modal.Header>
        <MapPositionSelect GPSlat={GPSlat} GPSlong={GPSlong} setGPSlat={setGPSlat} setGPSlong={setGPSlong} setShowMap={setShowMap}/>
      </Modal>
      <Modal show={showHelp} onHide={() => setShowHelp(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Povolené zápisy kategorií</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered>
            <tbody>
            <tr>
              <td>A;B</td>
              <td>kategorie A a B</td>
            </tr>
            <tr>
              <td>A|offroad;B</td>
              <td>kategorie A s poznámkou offroad a B</td>
            </tr>
            <tr>
              <td>A>A+;B</td>
              <td>kategorie A přejmenována na A+ a B</td>
            </tr>
            <tr>
              <td>A>A+|offroad;B</td>
              <td>kategorie A s poznámkou přejmenována na A+ a B</td>
            </tr>
            </tbody>
          </Table>
          <p>Maximální délka jména kategorie je 16 znaků, poznámky a přejmenování jsou volitelné. Jména kategorií jsou odděleny čárkou</p>
          <p>Znaky <b>; | ></b> (středník, svislítko, většítko) <b style={{color: "red"}}>nejsou</b> v názvech kategorií <b style={{color: "red"}}>povoleny</b>!</p>
        </Modal.Body>
      </Modal>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={() => setSuccess("")}/>
    </>
  );
};

export default EditTraining;