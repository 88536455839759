import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import {handleErrorLoading, handleErrorSubmit, setBrowserTabText} from "../../helpers/Functions";
import PageContainer from "../../layout/PageContainer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import FormField from "../../components/form/FormField";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import Table from "react-bootstrap/Table";
import React, {useEffect, useState} from "react";
import {getMembershipTypes, postPutMembershipType} from "../../dao";
import WarningCard from "../../components/overlays/WarningCard";


const UserMemberships = () => {
  const [data, setData] = useState({});
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const [typeToChange, setTypeToChange] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [short, setShort] = useState("");


  useEffect(() => {
    getMembershipTypes()
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {};
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = () => {
    setShowModal(false);
    const formData = {
      "title" : title,
      "short": short,
    };
    postPutMembershipType(typeToChange, formData)
      .then((res) => {
        if (res.ok) {
          setSuccess(typeToChange ? "Upraveno" : "Přidáno");
          res.json().then((response) => setData(response));
        } else {
          setError(handleErrorSubmit(res, "Operace se nezdařila"));
        }
      })
  }

  if (loading)
    return <Loader />;
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  setBrowserTabText('Správa typů členství');
  return (
    <PageContainer>
      <h1>Správa typů členství</h1>
      <Row>
        <Col className="mb-3 text-end">
          <Button onClick={() => {
            setTypeToChange(null);
            setTitle("");
            setShort("");
            setShowModal(true);
          }}>+ Přidat</Button>
        </Col>
      </Row>
      <MembershipTypesTable/>
      <Modal show={showModal} onHide={()=>setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{typeToChange ? "Upravit" : "Přidat"} typ členství</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormField label={"Zkratka"} value={short} setValue={setShort} valueLimit={2} isInvalid={short.length === 0} digits="none" inline={true} controlId={"short"} />
            <FormField label={"Název"} value={title} setValue={setTitle} valueLimit={32} isInvalid={title.length === 0} digits="none" inline={true} controlId={"title"} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Zavřít
          </Button>
          <Button variant="primary" disabled={title.length === 0 || short.length === 0} onClick={handleSubmit}>
            {typeToChange ? "Upravit" : "Přidat"}
          </Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={()=>setSuccess("")}/>
    </PageContainer>
  );

  function MembershipTypesTable() {
    const rows = [];
    for (const i in data?.memberships) {
      const type = data.memberships[i];
      rows.push(<tr key={i}>
        <td>{type.short}</td>
        <td>{type.title}</td>
        <td className="align-center">{type.profiles}</td>
        <td className="td-w-icon"><FontAwesomeIcon icon={faPencil} className="link-like" onClick={() => {
          setTypeToChange(type.id);
          setTitle(type.title);
          setShort(type.short);
          setShowModal(true);
        }}/></td>
      </tr>);
    }

    if (rows.length === 0)
      return <WarningCard text="Žádné typy členství" />;

    return (
      <Table striped bordered hover responsive>
        <thead>
        <tr>
          <th></th>
          <th>Název</th>
          <th>Poč.</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    );
  }
}

export default UserMemberships;