import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from 'react-bootstrap/Modal';
import { editRights, getRights } from "../../dao";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import Loader from "../../components/overlays/Loader";
import { faPlus, faQuestionCircle, faTrash, faGavel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "react-bootstrap/Table";
import { isMobileDevice } from "../../helpers/Device";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import {handleErrorLoading, setBrowserTabText, handleErrorSubmit} from "../../helpers/Functions";
import Card from "react-bootstrap/Card";
import PageContainer from "../../layout/PageContainer";
import Nav from "react-bootstrap/Nav";
import SearchBar from "../../components/form/SearchBar";

const RightsEdit = () => {
  const { user } = useParams();
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [userToRemove, setUserToRemove] = useState(null);
  const [userToRemoveReverse, setUserToRemoveReverse] = useState(null);
  const [showUserList, setShowUserList] = useState(false);
  const [addUserReverse, setAddUserReverse] = useState(false);
  const [showWardshipTab, setShowWardshipTab] = useState(false);

  useEffect(() => {
    setLoading(true);
    getRights(user)
      .then((response) => {
        if (response.ok)
          return response.json();
        setErrorRemote(handleErrorLoading(response));
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      });
  }, []);

  function removeRight(id){
    sendData(user, id, showWardshipTab, "DELETE");
    setUserToRemove(null);
  }

  function removeRightReverse(id){
    sendData(id, data.user.id, showWardshipTab, "DELETE");
    setUserToRemove(null);
    setUserToRemoveReverse(null);
  }

  function addRight(id){
    sendData(user, id, showWardshipTab, "PUT");
    setShowUserList(false);
  }

  function addRightReverse(id){
    sendData(id, data.user.id, showWardshipTab, "PUT");
    setShowUserList(false);
  }

  function sendData(user, id, wardship, method) {
    editRights(user, id, wardship, method)
      .then((res) => {
        if(res.ok){
          setSuccess("Uloženo");
          res.json().then((r) => setData(r))
        }else{
          setError(handleErrorSubmit(res, "Nezdařilo se uložit", "Nastavení"));
        }
      });
  }
  if (loading)
    return <Loader loading={loading} />
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  const accountOwner3 = user == null ? "tvému" : "tomuto";
  const accountOwner2 = user == null ? "tvého" : "tohoto";

  setBrowserTabText(user == null ? 'Práva' : 'Práva | ' + data?.user?.full_name);
  return (
    <PageContainer>
      {user == null ? <h1><FontAwesomeIcon icon={faGavel} /> Práva</h1> : <h1><FontAwesomeIcon icon={faGavel} /> Práva: {data?.user?.full_name}</h1> }
      <br/>
      <Card>
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey="#prava">
            <Nav.Item>
              <Nav.Link href="#prava" style={{fontSize: "18px"}} onClick={() => setShowWardshipTab(false)}>Přihlašovací práva</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#pridruzeni" style={{fontSize: "18px"}} onClick={() => setShowWardshipTab(true)}>Přidružení</Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Card.Body>
          {showWardshipTab ? <WardshipContent /> : <RightsContent />}
        </Card.Body>
      </Card>
      <AddRight
        show={showUserList && (!showWardshipTab || (showWardshipTab && data?.logged?.perms?.administrate && data.guardian == null))}
        onHide={() => {
          setShowUserList(false);
          setAddUserReverse(false);
        }}
        title={showWardshipTab ? (addUserReverse ? "Vybrat uživatele k přidružení" : "Vybrat uživatele jako nadřazeného"): "Vybrat uživatele pro přidání práva"}
        people={showWardshipTab ? (addUserReverse ? data.ward_options : data.guardian_options) : data.members}
        button={showWardshipTab ? (addUserReverse ? "Přidružit": "Nadřadit") : "Udělit právo"}
      />
      <Modal show={userToRemove != null || userToRemoveReverse != null} onHide={()=> {
        setUserToRemove(null);
        setUserToRemoveReverse(null);
      }}>
        <Modal.Header closeButton>
          <FontAwesomeIcon icon={faQuestionCircle} size='3x' bounce /> &nbsp;
          <Modal.Title>Skutečně odebrat právo?</Modal.Title>
          <Modal.Body>{userToRemove != null ?
            <>{userToRemove.reg_number}<br/>{userToRemove.full_name}</> :
            <>{userToRemoveReverse?.reg_number}<br/>{userToRemoveReverse?.full_name}</>}
          </Modal.Body>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=> {
            setUserToRemove(null);
            setUserToRemoveReverse(null);
          }}>Zavřít</Button>
          <Button variant="primary" onClick={()=> {
            userToRemove != null ? removeRight(userToRemove.id) : removeRightReverse(userToRemoveReverse.id);
          }}>Odebrat</Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={()=>setError("")}/>
      <SuccessAlert title={success} handleClose={()=>setSuccess("")}/>
    </PageContainer>
  );

  function RightsContent() {
    return (<>
      <Row>
        <Col className="col-7">
          <h3>Přihlašovací práva k {accountOwner3} účtu</h3>
        </Col>
        <Col className="col text-end">
          <Button variant="primary" onClick={() => setShowUserList(true)}>
            {isMobileDevice() ? <><FontAwesomeIcon icon={faPlus}/> Udělit</> : <><FontAwesomeIcon
              icon={faPlus}/> Udělit právo</>}
          </Button>
        </Col>
      </Row>
      <p>Mohou {user == null && "tě "} přihlašovat na akce a objednávat doplňkové služby.</p>
      <RightsTable/>
      <br/>
      <h3>Přihlašovací práva ke všem účtům</h3>
      {!data?.logged?.perms?.apply_all &&
        <p>Pokud právo udělíš některému z těchto uživatelů, budeš se jim pak zobrazovat nahoře v seznamu členů k
          přihlášení.</p>}
      <StaffTable/>
    </>)

    function RightsTable() {
      if (data.rights_settings.length < 1) {
        return(<><p><i>Žádní uživatelé nemají práva k {accountOwner3} účtu</i></p><br/></>)
      }
      const rows = [];
      data.rights_settings.forEach((user)=>{
        rows.push(<tr key={user.id}>
          <td>{user.reg_number}</td>
          <td>{user.full_name}</td>
          <td onClick={()=>setUserToRemove(user)} className="text-center pointing">
            <FontAwesomeIcon icon={faTrash} />
          </td>
        </tr>);
      });

      return (
        <Table striped bordered hover responsive>
          <thead>
          <tr>
            <th>Reg.</th>
            <th>Jméno</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </Table>
      );
    }

    function StaffTable(){
      if(isMobileDevice() || data.staff.length < 4){
        const rows = [];
        data.staff.forEach((user)=>{
          rows.push(<tr key={user.id}>
            <td>{user.reg_number}</td>
            <td>{user.full_name}</td>
          </tr>);
        });
        return (
          <Table striped bordered hover responsive>
            <thead>
            <tr>
              <th>Reg.</th>
              <th>Jméno</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
          </Table>
        );
      }
      const rows1 = [];
      const rows2 = [];
      for(var i = 0; i < Math.ceil(data.staff.length/2); i++){
        const user = data.staff[i];
        rows1.push(<tr key={user.id}>
          <td>{user.reg_number}</td>
          <td>{user.full_name}</td>
        </tr>);
      }
      for(var i = Math.ceil(data.staff.length/2); i < data.staff.length; i++){
        const user = data.staff[i];
        rows2.push(<tr key={user.id}>
          <td>{user.reg_number}</td>
          <td>{user.full_name}</td>
        </tr>);
      }
      return (
        <Row>
          <Col>
            <Table striped bordered hover responsive>
              <thead>
              <tr>
                <th>Reg.</th>
                <th>Jméno</th>
              </tr>
              </thead>
              <tbody>
              {rows1}
              </tbody>
            </Table>
          </Col>
          <Col>
            <Table striped bordered hover responsive>
              <thead>
              <tr>
                <th>Reg.</th>
                <th>Jméno</th>
              </tr>
              </thead>
              <tbody>
              {rows2}
              </tbody>
            </Table>
          </Col>
        </Row>
      );
    }
  }

  function WardshipContent() {
    return (<>
      {data.wards.length === 0 && <>
        <Row>
          <Col className="col-7">
            <h3>Nadřazený účet</h3>
          </Col>
          {data?.logged?.perms?.administrate && <Col className="col text-end">
            <Button variant="primary" onClick={() => setShowUserList(true)}>
              {isMobileDevice() ? <><FontAwesomeIcon icon={faPlus}/> Přidat</> : <><FontAwesomeIcon
                icon={faPlus}/> Nadřadit účet</>}
            </Button>
          </Col>}
        </Row>
        <br/>
        <GuardianContent/>
        <br/>
      </>}
      {data.guardian == null && <>
        <Row>
          <Col className="col-7">
            <h3>Přidružené účty</h3>
          </Col>
          {data?.logged?.perms?.administrate && <Col className="col text-end">
            <Button variant="primary" onClick={() => {
              setShowUserList(true);
              setAddUserReverse(true);
            }}>
              {isMobileDevice() ? <><FontAwesomeIcon icon={faPlus}/> Přidružit</> : <><FontAwesomeIcon
                icon={faPlus}/> Přidružit účet</>}
            </Button>
          </Col>}
        </Row>
        <br/>
        <WardsContent/>
      </>}
    </>)

    function GuardianContent() {
      const currentDate = new Date();
      return data.guardian == null ? (<>
        <p>Všechny náklady {accountOwner2} účtu se po nastavení budou přičítat k nadřazenému účtu. {data?.logged?.perms?.administrate ?
          <>Nastavení začne platit <b>1.1.{currentDate.getMonth() >= 10 ? currentDate.getFullYear() + 1 : currentDate.getFullYear()}</b>.</> :
          <>Přidání nadřazeného účtu lze vždy <b style={{color: "red"}}>v průběhu ledna a února</b>. Pro nastavení kontaktuj administrátora.</>}</p>
      </>) : (<>
        <Table striped bordered hover responsive>
          <thead>
          <tr>
            <th>Reg.</th>
            <th>Jméno</th>
            <th>Platnost</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{data.guardian.reg_number}</td>
            <td>{data.guardian.full_name}</td>
            <td>{data.guardian.year_from} – {data.guardian.year_to}</td>
            {data.guardian.year_to == null ?
              <td onClick={() => setUserToRemove(data.guardian)} className="text-center pointing">
                <FontAwesomeIcon icon={faTrash}/>
              </td> : <td></td>}
          </tr>
          </tbody>
        </Table>
        <p>Všechny náklady {accountOwner2} účtu se přičítají k nadřazenému účtu. Odebrání nadřazeného účtu se
          projeví <b
            style={{color: "red"}}>až od ledna
            následujícího roku</b>.</p>
      </>)
    }

    function WardsContent() {
      const currentDate = new Date();
      return data.wards.length === 0 ? (
        <p>Všechny náklady přidružených účtů se budou přičítat do vyúčtování {accountOwner2} účtu. {data?.logged?.perms?.administrate ?
          <>Nastavení začne platit <b>1.1.{currentDate.getMonth() >= 10 ? currentDate.getFullYear() + 1 : currentDate.getFullYear()}</b>.</> :
          <>Přidání přidruženého účtu lze vždy <b style={{color: "red"}}>av průběhu ledna a února</b>. Pro nastavení kontaktuj administrátora.</>}
        </p>
      ) : (<>
        <WardsTable/>
        <p>Všechny náklady přidružených účtů se přičítají k {accountOwner3} účtu. Odebrání přidruženého účtu se projeví <b style={{color: "red"}}>až od ledna
          následujícího roku</b>.</p>
      </>)

      function WardsTable() {
        const rows = [];
        data.wards.forEach((user) => {
          rows.push(<tr key={user.id}>
            <td>{user.reg_number}</td>
            <td>{user.full_name}</td>
            <td>{user.year_from} – {user.year_to}</td>
            {user.year_to == null ?
              <td onClick={() => setUserToRemoveReverse(user)} className="text-center pointing">
                <FontAwesomeIcon icon={faTrash}/>
              </td> : <td></td>}
          </tr>);
        });

        return (
          <Table striped bordered hover responsive>
            <thead>
            <tr>
              <th>Reg.</th>
              <th>Jméno</th>
              <th>Platnost</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
          </Table>
        );
      }

    }
  }

  function AddRight(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PeopleTable people={props.people} button={props.button}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Zavřít</Button>
        </Modal.Footer>
      </Modal>
    );

    function PeopleTable(props){
      const [filteredPeople, setFilteredPeople] = useState(props.people);

      if(props.people?.length > 0){
        const rows = [];
        for(var i in filteredPeople){
          const person = filteredPeople[i];
          rows.push(
            <tr key={person.id}>
              <td>{person.reg_number}</td>
              <td>{person.full_name}</td>
              <td>
                <Button onClick={()=> {addUserReverse ? addRightReverse(person.id) : addRight(person.id)}}>{props.button}</Button>
              </td>
            </tr>
          );
        }
        return(
          <>
            <SearchBar data={props.people} setFilteredPeople={setFilteredPeople} />
            <Table>
              <tbody>
              {rows}
              </tbody>
            </Table>
          </>
        )
      }
      return(<p>Bohužel nemůžeš přidat práva žádným dalším uživatelům.</p>);
    }
  }
};

export default RightsEdit;