import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import React from "react";


const ModalContentDict = ({options}) => {
  const rows = [];
  for (const short in options) {
    const item = options[short];
    rows.push(<tr>
      <td className="td-w-10">{short}</td>
      <td>{item}</td>
    </tr>)
  }
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Nápověda</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Modal.Title>Skupiny</Modal.Title>
        <Table bordered>
          <tbody>
          {rows}
          </tbody>
        </Table>
      </Modal.Body>
    </>
  )
}

export default ModalContentDict;