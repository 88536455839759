import { useState } from "react";
import {Theme} from "./Constants";
export default function useFilterCache(){
    const [month, setMonth] = useState(getMonth());
    const [year, setYear] = useState(getYear());
    const [type, setType] = useState(getType());
    const [region, setRegion] = useState(getRegion());
    const [sport, setSport] = useState(getSport());
    const [cancelled, setCancelled] = useState(getCancelled());

    function getMonth(){
        const value = JSON.parse(sessionStorage.getItem('filterMonth'));
        return value ? value+"" : 0;
    }

    function getYear(){
        const value = JSON.parse(sessionStorage.getItem('filterYear'));
        return value ? value : new Date().getFullYear();
    }

    function getType(){
        let value = JSON.parse(sessionStorage.getItem('filterType'));
        const month = (new Date()).getMonth() + 1;
        if (!isNaN(Number(value)))
            value = "*"
        return value ? value : (Theme === "PGP" ? "K" : (month < 3 || month > 10) ? "*" : "O");
    }

    function getRegion(){
        const value = JSON.parse(sessionStorage.getItem('filterRegion'));
        return value ? value : "";
    }

    function getSport(){
        const value = JSON.parse(sessionStorage.getItem('filterSport'));
        return value ? value : 0;
    }

    function getCancelled(){
        const value = JSON.parse(sessionStorage.getItem('filterCancelled'));
        return value ? value : "0";
    }

    function setMonthFilter(value){
        setMonth(value);
        sessionStorage.setItem('filterMonth', JSON.stringify(value));
    }
    function setYearFilter(value){
        setYear(value);
        sessionStorage.setItem('filterYear', JSON.stringify(value));
    }
    function setTypeFilter(value){
        setType(value);
        sessionStorage.setItem('filterType', JSON.stringify(value));
    }
    function setRegionFilter(value){
        setRegion(value);
        sessionStorage.setItem('filterRegion', JSON.stringify(value));
    }
    function setSportFilter(value){
        setSport(value);
        sessionStorage.setItem('filterSport', JSON.stringify(value));
    }
    function setCancelledFilter(value){
        setCancelled(value);
        sessionStorage.setItem('filterCancelled', JSON.stringify(value));
    }

    return{
        month: month,
        setMonth: setMonthFilter,
        year: year,
        setYear: setYearFilter,
        type: type,
        setType: setTypeFilter,
        region: region,
        setRegion: setRegionFilter,
        sport: sport,
        setSport: setSportFilter,
        cancelled : cancelled,
        setCancelled : setCancelledFilter
    }
}

