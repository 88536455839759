import {faLeaf} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { ReactComponent as FootR } from '../../static/footR.svg';
import { ReactComponent as FootL } from '../../static/footL.svg';
import { ReactComponent as ControlPoint } from '../../static/control.svg';
import {Theme} from "../../helpers/Constants";

const Loader = () => {
  if (Theme === 'PGP')
    return <ControlIcon />;
  const date = new Date();
  if (date.getMonth() >= 9 && date.getMonth() <= 10 && date.getHours() >= 7 && date.getHours() < 19)
    return <FallingLeaves />;
  return <Footprints />;
};

function ControlIcon() {
  return (
    <div className="loader-container" style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <ControlPoint className="spin-pulse" style={{
        height: "9em",
        width: "9em",
      }} />
    </div>
  )
}

function FallingLeaves() {
  const leavesArray = new Array(15).fill(null);  // Create 10 leaves

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  return (
    <div className="falling-leaves-container">
      {leavesArray.map((_, index) => (
        <FontAwesomeIcon icon={faLeaf} className={getRandomInt(3) === 0 ? "leaf center" : (getRandomInt(2) === 0 ? "leaf upper" : "leaf lower")} key={index}/>
      ))}
    </div>
  );
}

function Footprints() {
  return (
    <div className="loader-container" style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <div>
        <Row>
          <Col style={{ paddingRight: 0 }}>
            <FootL style={{
              height: "2.3em",
              width: "2em",
              opacity: 0,
              animationName: 'appearing',
              animationDuration: '1.2s',
              animationIterationCount: 'infinite',
              animationDelay: '1.1s'
            }} />
          </Col>
          <Col style={{ paddingLeft: 0, paddingTop: '15px' }}>
            <FootR style={{
              height: "2em",
              width: "2em",
              opacity: 0,
              animationName: 'appearing',
              animationDuration: '1.2s',
              animationIterationCount: 'infinite',
              animationDelay: '0.95s'
            }} />
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingRight: 0 }}>
            <FootL style={{
              height: "2.3em",
              width: "2em",
              opacity: 0,
              animationName: 'appearing',
              animationDuration: '1.2s',
              animationIterationCount: 'infinite',
              animationDelay: '0.8s'
            }} />
          </Col>
          <Col style={{ paddingLeft: 0, paddingTop: '15px' }}>
            <FootR style={{
              height: "2em",
              width: "2em",
              opacity: 0,
              animationName: 'appearing',
              animationDuration: '1.2s',
              animationIterationCount: 'infinite',
              animationDelay: '0.6s'
            }} />
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingRight: 0 }}>
            <FootL style={{
              height: "2.3em",
              width: "2em",
              opacity: 0,
              animationName: 'appearing',
              animationDuration: '1.2s',
              animationIterationCount: 'infinite',
              animationDelay: '0.4s'
            }} />
          </Col>
          <Col style={{ paddingLeft: 0, paddingTop: '15px' }}>
            <FootR style={{
              height: "2em",
              width: "2em",
              opacity: 0,
              animationName: 'appearing',
              animationDuration: '1.2s',
              animationIterationCount: 'infinite',
              animationDelay: '0.2s'
            }} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Loader;