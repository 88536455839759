import {redirect} from "react-router-dom";
import {API_URL} from "./helpers/Constants";

function getCsrfTokenFromDocument() {
  for (const c in document.cookie.split(';')) {
    const c_split = c.split('=')
    if (c_split.length !== 2)
      continue
    if (c_split[0] === "csrf-token") {
      const csrfValue = c_split[1].trim();
      if (csrfValue !== "")
        return csrfValue
    }
  }
  return ""
}

function getCsrfToken(){
  let csrfToken = getCsrfTokenFromDocument();
  if(csrfToken === ""){
    csrfToken = localStorage.getItem('csrf');
    if(csrfToken == null){
      redirect("/login");
    }
  }
  return csrfToken;
}
function setCsrfToken(_csrftoken){
  localStorage.setItem('csrf', _csrftoken);
}

function deleteCookie(name, domain) {
  document.cookie = `${name}=; Path=/; Domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

async function getCookie(){
  return await fetch(API_URL+"csrf-cookie", {
    headers: {
      'Access-Control-Allow-Origin': '*'
  }, credentials: 'include'});
}

async function getFieldOptions() {
  return await fetch(API_URL+"field-options", {credentials: 'include'});
}

async function getServerInfo() {
  return await fetch(API_URL+"server-info", {credentials: 'include'});
}

async function getServerSettings() {
  return await fetch(API_URL+"server-settings", {credentials: 'include'});
}

async function getServerEmailQueue(showAll) {
  return await fetch(API_URL+"server-email-queue?" + new URLSearchParams({'all' : showAll ? 1 : 0}) , {credentials: 'include'});
}

async function getServerAsyncRuns(date) {
  return await fetch(API_URL+"server-async-runs?" + new URLSearchParams({'date' : date}) , {credentials: 'include'});
}

async function getServerActionLog(date) {
  return await fetch(API_URL+"server-action-log?" + new URLSearchParams({'date' : date}) , {credentials: 'include'});
}

async function putServerSettings(message, info, sendEmails) {
  const data = {
    "message" : message, "info" : info, "send_emails": sendEmails,
  }
  return await fetch(API_URL + "server-settings", {
    method: "PUT",
    credentials: "include",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    body: JSON.stringify(data),
  });
}

async function postLogin(username, password, _csrftoken) {
  setCsrfToken(_csrftoken);
  // Attempt to delete the problematic cookies (associated with api.* domain)
  const domain = API_URL.replace('https://', '').replace('/', '');
  deleteCookie('csrftoken', domain);
  deleteCookie('sessionid', domain);
  let formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);
  const data = new URLSearchParams(formData);
  return await fetch(API_URL+"login", {
    method: "POST",
    credentials: "include",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      "x-csrftoken": _csrftoken
    },
    body: data.toString(), // body data type musí sedět s "Content-Type" header
  });
}

async function postEnroll(eventId, userId, classId, className, si, rentSi,
  startRequest, note, clubNote, carCapacity, carInfo, transport, accommodation, stages, oneTimeEntry, oneTimeName, oneTimeSurname, mode) {
  const data = {
    "person" : oneTimeEntry ? null : userId,
    "class_id" : classId,
    "class_name" : className, 
    "si" : si, 
    "rent_si" : rentSi, 
    "start_request" : startRequest, 
    "note" : note,
    "club_note" : clubNote,
    "car_capacity" : carCapacity,
    "car_info": carInfo,
    "transport" : transport, 
    "accommodation" : accommodation,
    "stages": stages,
    "one_time_name": oneTimeName,
    "one_time_surname": oneTimeSurname,
  }
  let params = new URLSearchParams({"event": eventId})
  if (mode === "PUT")
    params.append("user", userId);
  return await fetch(API_URL + "create-update-entry?" + params, {
    method: mode,
    credentials: "include",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    body: JSON.stringify(data), 
  });
}

async function sendCampEnroll(eventId, userId, form, si, rentSi, transport, carCapacity, note, mode){
  const data = {
    "person" : userId,
    "form": form,
    "si" : si,
    "rent_si" : rentSi,
    "car_capacity" : carCapacity,
    "transport" : transport,
    "note" : note,
  }
  let params = new URLSearchParams({"event": eventId})
  if (mode === "PUT")
    params.append("user", userId);
  return await fetch(API_URL + "add-edit-entry?" + params, {
    method: mode,
    credentials: "include",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    body: JSON.stringify(data), 
  });
}

async function sendCampAnonymEnroll(eventId, userId, userData, form, si, transport, note, mode, csrfToken){
  setCsrfToken(csrfToken);
  const data = {
    "person" : userId,
    "user_data": userData,
    "form": form,
    "si" : si,
    "rent_si" : false,
    "car_capacity" : 0,
    "transport" : transport,
    "note" : note,
  }
  let params = new URLSearchParams({"event": eventId})
  if (mode === "PUT")
    params.append("user", userId);
  return await fetch(API_URL + "add-edit-entry-anonym?" + params, {
    method: mode,
    credentials: "include",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    body: JSON.stringify(data),
  });
}

async function deleteEnroll(eventId, userId) {
  const params = new URLSearchParams({
    "event": eventId
  });
  if(userId != null){
    params.append("user", userId);
  }
  return await fetch(API_URL + "create-update-entry?" + params, {
    method: "DELETE",
    headers: {
      "x-csrftoken": getCsrfToken()
    }, credentials: 'include'
  });
}

async function deleteEnrollAnonym(eventId, userId, csrfToken) {
  setCsrfToken(csrfToken);
  return await fetch(API_URL + "add-edit-entry-anonym?" + new URLSearchParams({
    "event": eventId, "user": userId
  }), {
    method: "DELETE",
    headers: {
      "x-csrftoken": getCsrfToken()
    }, credentials: 'include'
  });
}

async function getEnrollInfo(eventId, userId, oneTimeEntry){
  const params = new URLSearchParams({
    "event": eventId,
  });
  if(userId != null){
    params.append("user", userId);
  }
  if (oneTimeEntry)
    return await fetch(API_URL+"get-entry-blank?" + params, {credentials: 'include'});
  return await fetch(API_URL+"get-entry?" + params, {credentials: 'include'});
}

async function getEnrollAnonymInfo(eventId, userId){
  const params = new URLSearchParams({
    "event": eventId,
  });
  if(userId != null)
    params.append("user", userId);
  return await fetch(API_URL+"get-entry-anonym?" + params, {credentials: 'include'});
}

async function getClubEnrolls(eventId, splitting){
  return await fetch(API_URL+"get-entries?" + new URLSearchParams({
    "event": eventId, "splitting" : splitting ? 1 : 0
  }), {credentials: 'include'});
}

async function getClubEnrollsAdmin(eventId){
  return await fetch(API_URL+"get-entries-admin?" + new URLSearchParams({
    "event": eventId
  }), {credentials: 'include'});
}

async function getEventApplyRights(eventId) {
  return await fetch(API_URL+"get-event-apply-rights?" + new URLSearchParams({
    "event": eventId
  }), {credentials: 'include'});
}

async function getUserInfo(){
  return await fetch(API_URL+"user-info", {credentials: 'include'});
}

async function getHome(year, month, type, regionFilter, sportFilter, cancelledFilter){
  var params = new URLSearchParams({
    "year": year,
    "month": month,
    "sport": sportFilter,
    "type" : type
  });
  if(cancelledFilter){
    params.append("cancelled", cancelledFilter);
  }
  if(regionFilter !== ""){
    params.append("region", regionFilter);
  }
  return await fetch(API_URL+"get-home?" + params
  , {credentials: 'include'});
}

async function getCups(year, sport, region) {
  var params = new URLSearchParams({
    "year": year, "sport": sport, "region": region
  });
  return await fetch(API_URL+"get-cups?" + params, {credentials: 'include'});
}

async function getMembers(){
  return await fetch(API_URL+"get-members", {credentials: 'include'});
}

async function getMembersAdmin(account, role){
  return await fetch(API_URL+"get-members-admin?" + new URLSearchParams({
    "account": account,
    "role": role
  }), {credentials: 'include'});
}

async function getUserRoles(){
  return await fetch(API_URL+"edit-user-roles", {credentials: 'include'});
}

async function postPutUserRole(roleId, formData){
  let target = API_URL + "edit-user-roles"
  if (roleId)
    target = target + "?role=" + roleId
  return await fetch(target, {
    method: (roleId ? "PUT" : "POST"),
    credentials: "include",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    body: JSON.stringify(formData),
  });
}

async function getUserGroups(){
  return await fetch(API_URL+"edit-user-groups", {credentials: 'include'});
}

async function postPutUserGroup(groupId, formData){
  let target = API_URL + "edit-user-groups"
  if (groupId)
    target = target + "?group=" + groupId
  return await fetch(target, {
    method: (groupId ? "PUT" : "POST"),
    credentials: "include",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    body: JSON.stringify(formData),
  });
}

async function getMembershipTypes(){
  return await fetch(API_URL+"edit-membership-types", {credentials: 'include'});
}

async function postPutMembershipType(typeId, formData){
  let target = API_URL + "edit-membership-types"
  if (typeId)
    target = target + "?type=" + typeId
  return await fetch(target, {
    method: (typeId ? "PUT" : "POST"),
    credentials: "include",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    body: JSON.stringify(formData),
  });
}

async function getClubStatistics(year){
  return await fetch(API_URL+"get-club-statistics?" + new URLSearchParams({'year': year}), {credentials: 'include'});
}

async function getBillingYears() {
  return await fetch(API_URL+"club-billing-years", {credentials: 'include'});
}

async function postNewBillingYear() {
  return await fetch(API_URL+"club-billing-years", {method: "POST", headers: {
      "x-csrftoken": getCsrfToken()
    }, credentials: 'include'});
}

async function putBillingYears(year, periods) {
  return await fetch(API_URL+"club-billing-years?" + new URLSearchParams({'year': year}), {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    body: JSON.stringify({"periods": periods}),
    credentials: 'include'});
}

async function getEvent(id){
  return await fetch(API_URL+"get-event?id="+id, {credentials: 'include'});
}

async function getEventSync(id){
  return await fetch(API_URL+"get-event-sync?id="+id, {credentials: 'include'});
}

async function getEditEvent(id){
  return await fetch(API_URL+"edit-event?id="+id, {credentials: 'include'});
}

async function editEvent(eventId, formData){
  return await fetch(API_URL + "edit-event?" + new URLSearchParams({
    "id": eventId
  }), {
    method: "PUT",
    credentials: "include",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    body: JSON.stringify(formData),
  });
}

async function getMirroring(event) {
  return await fetch(API_URL+"edit-mirroring?event="+event, {credentials: 'include'});
}

async function putMirroring(source, mirror) {
  return await fetch(API_URL+"edit-mirroring?" + new URLSearchParams({
    "source": source,
    "mirror": mirror
  }), {
    method: "PUT",
    credentials: "include",
    headers: {
      "x-csrftoken": getCsrfToken()
    },
  });
}

async function deleteMirroring(source, mirror) {
  return await fetch(API_URL+"edit-mirroring?" + new URLSearchParams({
    "source": source,
    "mirror": mirror
  }), {
    method: "DELETE",
    credentials: "include",
    headers: {
      "x-csrftoken": getCsrfToken()
    },
  });
}

async function createTraining(formData){
  return await fetch(API_URL + "add-training", {
    method: "POST",
    credentials: "include",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    body: JSON.stringify(formData), 
  });
}

async function createCamp(title, date, dateTo, place, info, event_info, event_warning, punching, transport, transportInfo, visible, term_first, form){
  let formData = {
    "title": title, 
    "date": date,
    "date_to": dateTo, 
    "place" : place, 
    "info": info,
    "event_info": event_info,
    "event_warning": event_warning,
    "punching": punching,
    "transport": transport,
    "transport_info": transportInfo,
    "visible": visible, 
    "term_first": term_first, 
    "form": form
  }
  return await fetch(API_URL + "add-camp", {
    method: "POST",
    credentials: "include",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    body: JSON.stringify(formData), 
  });
}

async function getCampsToCopy(event, year){
  return await fetch(API_URL + "copy-questions?" + new URLSearchParams({
    "event": event, "year": year
  }), { credentials: "include"});
}

async function putCampCopy(event, source) {
  return await fetch(API_URL + "copy-questions?" + new URLSearchParams({
    "event": event, "source": source
  }), {
    method: "PUT",
    credentials: "include",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
  });
}

async function getQuestionsEdit(event) {
  return await fetch(API_URL + "edit-questions?" + new URLSearchParams({
    "event": event,
  }), { credentials: "include"});
}

async function postPutQuestion(method, param, title, note, required, multichoice, other, choices) {
  let formData = {
    "title": title,
    "note": note,
    "required": required,
    "multichoice": multichoice,
    "other_choice": other,
    "choices": choices,
  }
  let target_url = API_URL + "edit-questions?" + param;
  return await fetch(target_url, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    credentials: 'include',
    body: JSON.stringify(formData),
  });
}

async function putMoveQuestion(id, index) {
  return await fetch(API_URL + "move-question?" + new URLSearchParams({
    "id": id,
    "index": index
  }), {
    method: "PUT",
    credentials: "include",
    headers: {
      "x-csrftoken": getCsrfToken()
    },
  });
}

async function deleteQuestion(id) {
  return await fetch(API_URL + "edit-questions?" + new URLSearchParams({
    "id": id,
  }), {
    method: "DELETE",
    credentials: "include",
    headers: {
      "x-csrftoken": getCsrfToken()
    },
  });
}

async function getEditLinks(event){
  return await fetch(API_URL+"edit-links?" + new URLSearchParams({
    "event": event
  }), {credentials: 'include'});
}

async function postPutLink(id, title, url, method){
  let params = method === 'POST' ?
    new URLSearchParams({"event": id}) : new URLSearchParams({"id": id});
  let formData = {
    "title": title,
    "url": url
  }
  return await fetch(API_URL+"edit-links?" + params, {
    method: method,
    credentials: "include",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    body: JSON.stringify(formData),
  });
}

async function deleteLink(id){
  return await fetch(API_URL+"edit-links?" + new URLSearchParams({
    "id": id
  }), {
    method: "DELETE",
    credentials: "include",
    headers: {
      "x-csrftoken": getCsrfToken()
    },
  });
}


async function getUserDetail(user){
  if (user == null)
    return await fetch(API_URL+"edit-profile", {credentials: 'include'});
  return await fetch(API_URL+"edit-profile?" + new URLSearchParams({
      "user": user
    }), {credentials: 'include'});
}

async function editUser(user, formData){
  const editProfileUrl = user == null ? API_URL + "edit-profile" : API_URL + "edit-profile?user=" + user;
  return await fetch(editProfileUrl, {
    method: "PUT",
    credentials: "include",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    body: JSON.stringify(formData), 
  });
}

async function getUserOptionHistory(user){
  if (user == null)
    return await fetch(API_URL+"user-membership-history", {credentials: 'include'});
  return await fetch(API_URL+"user-membership-history?" + new URLSearchParams({
    "user": user
  }), {credentials: 'include'});
}

async function changeAccountLock(user, action) {
  return await fetch(API_URL+"change-account-lock?" + new URLSearchParams({
    "user": user, "action": action
  }), {
    method: "PUT",
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    }
  });
}

async function getUsersToCreate() {
  return await fetch(API_URL+"create-user", {credentials: 'include'});
}

async function createUser(formData, mode="manual"){
  return await fetch(API_URL + "create-user?mode=" + mode, {
    method: "POST",
    credentials: "include",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    body: JSON.stringify(formData)
  });
}

async function updateUsersCsv(formData){
  return await fetch(
    API_URL+'update-users',
    {
      method: 'POST',
      headers: {
        "x-csrftoken": getCsrfToken()
      },
      credentials: "include",
      body: formData
    }
  )
}

async function getUserStats(user, year){
  const params = new URLSearchParams({"year": year});
  if (user != null)
    params.append("user", user)
  return await fetch(API_URL+"get-statistics?" + params, {credentials: 'include'});
}

async function getEventRank(eventId){
  return await fetch(API_URL+"get-ranking-stats?" + new URLSearchParams({
    "event": eventId
  }), {credentials: 'include'});
}

async function getMyEvents(user, year){
  const params = new URLSearchParams({"year": year});
  if (user != null)
    params.append("user", user)
  return await fetch(API_URL+"get-my-list?" + params, {credentials: 'include'});
}

async function getBilling(user, year, stable){
  const params = new URLSearchParams({"year": year, "stable": stable ? 1 : 0});
  if (user != null)
    params.append("user", user)
  return await fetch(API_URL+"get-billing?" + params, {credentials: 'include'});
}

async function getBillingSummary(user){
  if (user == null)
    return await fetch(API_URL+"get-billing-summary", {credentials: 'include'});
  return await fetch(API_URL+"get-billing-summary?" + new URLSearchParams({
    "user": user
  }), {credentials: 'include'});
}

function getPaymentQRCode(user) {
  if (user == null)
    return fetch(API_URL+"get-payment-qr", {credentials: 'include', headers: {
        'Content-Type': 'image/png',
      }});
  return fetch(API_URL+"get-payment-qr?" + new URLSearchParams({"user": user}),
    {credentials: 'include', headers: {'Content-Type': 'image/png'}});
}

async function getUserPayments(user, year){
  let params = new URLSearchParams({"year": year});
  if (user != null)
    params.append("user", user);
  return await fetch(API_URL+"get-payments?" + params, {credentials: 'include'});
}

async function getClubBilling(year){
  return await fetch(API_URL+"get-club-billing?year=" + year, {credentials: 'include'});
}

async function getClubBillingDetail(eventMode, year, month, period){
  const params = new URLSearchParams({
    'year': year, 'mode': eventMode ? 'event' : 'member', 'month': month, 'period': period
  });
  return await fetch(API_URL+"club-billing-detail?" + params, {credentials: 'include'});
}

async function changeGrantLimit(year, limitOldest, limitYoungest) {
  return await fetch(API_URL+'club-billing-grant-limit?' + new URLSearchParams({"year": year, "limit_oldest": limitOldest, "limit_youngest": limitYoungest}),
    {
      method: 'PUT',
      headers: {
        "x-csrftoken": getCsrfToken()
      },
      credentials: "include",
    }
  )
}

async function getPeriods() {
  return await fetch(API_URL+'billing-periods', {credentials: 'include'})
}

async function stepPeriod(step) {
  return await fetch(API_URL+'billing-periods?' + new URLSearchParams({"step": step}), {
      method: 'PUT',
      headers: {
        "x-csrftoken": getCsrfToken()
      },
      credentials: "include",
    }
  )
}

async function getClubRanking(){
  return await fetch(API_URL+"get-club-ranking", {credentials: 'include'});
}

async function getServices(eventId){
  return await fetch(API_URL+"get-services?event="+eventId, {credentials: 'include'});
}

async function orderService(serviceId, person, quantity, note, paidByClub){
  return await fetch(API_URL+"add-order?service="+serviceId, {
    method: "POST",
    credentials: 'include',
    body: JSON.stringify({
      person: person,
      quantity: quantity,
      note: note,
      paid_by_club: paidByClub
    }),
    headers: {
      "x-csrftoken": getCsrfToken(),
      'Content-Type': 'application/json'
    }
  });
}

async function updateOrder(orderId, edited, quantity, note, paidByClub, manualFee){
  return await fetch(API_URL+"edit-order?id="+orderId, {
    method: "PUT",
    credentials: 'include',
    body: JSON.stringify({
      edited: edited,
      quantity: quantity,
      note: note,
      paid_by_club: paidByClub,
      fee: manualFee
    }),
    headers: {
      "x-csrftoken": getCsrfToken(),
      'Content-Type': 'application/json'
    }
  });
}

async function deleteOrder(orderId){
  return await fetch(API_URL+"edit-order?id="+orderId, {
    method: "DELETE",
    headers: {
      "x-csrftoken": getCsrfToken()
    }, credentials: 'include'
  });
}

async function getTransportInfo(eventId){
  return await fetch(API_URL+"get-club-transport?event="+eventId, {credentials: 'include'});
}

async function getTransportEditInfo(eventId){
  return await fetch(API_URL+"edit-club-transport?event="+eventId, {credentials: 'include'});
}

async function editTransport(eventId, vehicles, transportHandled){
  const formData = {
    transport_handled: transportHandled,
    vehicles: vehicles
  }
  let params = new URLSearchParams({"event": eventId});
  let target_url = API_URL + "edit-club-transport?" + params;
  return await fetch(target_url, {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    }, 
    credentials: 'include',
    body: JSON.stringify(formData)
  });
}

async function getMyVehicle(eventId){
  return await fetch(API_URL+"get-my-vehicle?event="+eventId, {credentials: 'include'});
}

async function editMyTransport(vehicleId, mode, subject){
  const formData = mode === 'crew' ? {'crew': subject } : {'info': subject}
  let target_url = API_URL + "edit-my-transport?" + new URLSearchParams({"id": vehicleId, "mode": mode});
  return await fetch(target_url, {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    credentials: 'include',
    body: JSON.stringify(formData)
  });
}

async function getClothes(){
  return await fetch(API_URL+"get-clothes", {credentials: 'include'});
}

// async function addClothes(title, price, img, sizes) {
//   return await fetch(api_url + "create-clothes", {
//     method: "POST",
//     headers: {
//       "x-csrftoken": getCsrfToken()
//     }, credentials: 'include'
//   });
// }

async function getRights(user){
  if (user == null)
    return await fetch(API_URL+"edit-rights", {credentials: 'include'});
  return await fetch(API_URL+"edit-rights?" + new URLSearchParams({
    "user": user
  }), {credentials: 'include'});
}

async function getMyRights(user){
  if (user == null)
    return await fetch(API_URL+"get-rights", {credentials: 'include'});
  return await fetch(API_URL+"get-rights?" + new URLSearchParams({
    "user": user
  }), {credentials: 'include'});
}

async function editRights(user, rightful, wardship, method){
  let params = new URLSearchParams({"rightful": rightful});
  if (user != null)
    params.append("user", user);
  if (wardship === true)
    params.append("wardship", "1");
  let target_url = API_URL + "edit-rights?" + params;
  return await fetch(target_url, {
    method: method,
    headers: {
      "x-csrftoken": getCsrfToken()
    }, 
    credentials: 'include',
  });
}

async function getEditors(event){
  return await fetch(API_URL+"edit-editors?" + new URLSearchParams({
    "event": event
  }), {credentials: 'include'});
}

async function editEditors(event, user, method){
  let target_url = API_URL + "edit-editors?" + new URLSearchParams({"event": event, "user": user});
  return await fetch(target_url, {
    method: method,
    headers: {
      "x-csrftoken": getCsrfToken()
    },
    credentials: 'include',
  });
}

async function exportAppliedMails(target_url) {
  return await fetch(target_url, {credentials: 'include'});
}

async function exportCsv(target_url) {
  return await fetch(target_url, {credentials: 'include', headers: {
      'Content-Type': 'text/csv',
    }});
}

async function getRequisitions(event) {
  return await fetch(API_URL+"edit-requisitions?" + new URLSearchParams({
    "event": event
  }), {credentials: 'include'});
}

async function postRequisition(event, user, fee) {
  return await fetch(API_URL+"edit-requisitions?" + new URLSearchParams({
    "event": event
  }), {
    method: "POST",
    credentials: 'include',
    body: JSON.stringify({
      user: user,
      fee: fee
    }),
    headers: {
      "x-csrftoken": getCsrfToken(),
      'Content-Type': 'application/json'
    }
  });
}

async function postRequisitionCsv(event, formData) {
  return await fetch(
    API_URL+"import-requisitions?" + new URLSearchParams({
      "event": event
    }),
    {
      method: 'POST',
      headers: {
        "x-csrftoken": getCsrfToken()
      },
      credentials: "include",
      body: formData
    }
  )
}

async function requestPasswordReset(username, _csrftoken){
  return await fetch(API_URL+"request-password-reset", {
    method: "POST",
    credentials: 'include',
    body: JSON.stringify({"value": username}),
    headers: {
      "x-csrftoken": _csrftoken,
      'Content-Type': 'application/json'
    }
  });
}

async function resetPassword(password, token, _csrftoken){
  return await fetch(API_URL+"reset-password?token="+token, {
    method: "POST",
    credentials: 'include',
    body: JSON.stringify({"password": password}),
    headers: {
      "x-csrftoken": _csrftoken,
      'Content-Type': 'application/json'
    }
  });
}

async function putRequisition(param, formData) {
  return await fetch(API_URL+"edit-requisitions?" + param, {
      method: 'PUT',
      headers: {
        "x-csrftoken": getCsrfToken(),
        'Content-Type': 'application/json',
      },
      credentials: "include",
      body: JSON.stringify(formData)
    }
  )
}

async function deleteRequisition(id) {
  return await fetch(API_URL + "edit-requisitions?" + new URLSearchParams({
    "req": id
  }), {
    method: "DELETE",
    headers: {
      "x-csrftoken": getCsrfToken()
    }, credentials: 'include'
  });
}

async function getMembersForPayments() {
  return await fetch(API_URL + "get-members-for-payments", {credentials: 'include'});
}

async function getPayments(year, month, viewMode) {
  return await fetch(API_URL + "edit-payments?" + new URLSearchParams({
    "year": year, "month": month, "mode": viewMode
  }), {credentials: 'include'});
}

async function postPaymentCsv(formData) {
  return await fetch(API_URL + "edit-payments",
    {
      method: 'POST',
      headers: {
        "x-csrftoken": getCsrfToken()
      },
      credentials: "include",
      body: formData
    }
  )
}

async function putPayment(id, user) {
  const formData = {
    "user": user
  };
  return await fetch(
    API_URL + "edit-payments?" + new URLSearchParams({"id": id}),
    {
      method: 'PUT',
      headers: {
        "x-csrftoken": getCsrfToken(),
        'Content-Type': 'application/json',
      },
      credentials: "include",
      body: JSON.stringify(formData)
    }
  )
}

async function movePayments(mode, payments) {
  return await fetch(
    API_URL + "move-payments?" + new URLSearchParams({"to": mode}),
    {
      method: 'PUT',
      headers: {
        "x-csrftoken": getCsrfToken(),
        'Content-Type': 'application/json',
      },
      credentials: "include",
      body: JSON.stringify({"payments": payments})
    }
  )
}

async function deletePayment(id) {
  return await fetch(API_URL + "edit-payments?" + new URLSearchParams({
    "id": id
  }), {
    method: "DELETE",
    headers: {
      "x-csrftoken": getCsrfToken()
    }, credentials: 'include'
  });
}

async function getRequisitionEvents(year, mode) {
  return await fetch(API_URL+"edit-requisition-events?" + new URLSearchParams({
    "year": year, "mode": mode
  }), {credentials: 'include'});
}

async function postRequisitionEvent(title, date, type) {
  let formData = {
    "title": title,
    "date": date,
    "type": Number(type),
  }
  return await fetch(API_URL+"edit-requisition-events", {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    credentials: 'include',
    body: JSON.stringify(formData)
  });
}

async function putRequisitionEvent(event) {
  return await fetch(API_URL+"edit-requisition-events?" + new URLSearchParams({
    "event": event
  }), {
    method: "PUT",
    headers: {
      "x-csrftoken": getCsrfToken()
    }, credentials: 'include'
  });
}

async function deleteRequisitionEvent(event) {
  return await fetch(API_URL + "edit-requisition-events?" + new URLSearchParams({
    "event": event
  }), {
    method: "DELETE",
    headers: {
      "x-csrftoken": getCsrfToken()
    }, credentials: 'include'
  });
}

async function getForeignEvents(year) {
  return await fetch(API_URL+"edit-requisition-foreign-events?" + new URLSearchParams({
    "year": year
  }), {credentials: 'include'});
}

async function putExchangeRate(event, rate) {
  return await fetch(API_URL+"set-exchange-rate?" + new URLSearchParams({
    "event": event
  }), {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    }, credentials: 'include',
    body: JSON.stringify({'rate': rate})
  });
}

async function sendEmails(params) {
  return await fetch(API_URL + "send-emails-bills?" + params, {
    method: "POST",
    headers: {
      "x-csrftoken": getCsrfToken()
    }, credentials: 'include'
  });
}

async function resendWelcomeEmail(user) {
  return await fetch(API_URL + "resend-welcome-mail?user=" + user, {
    method: "POST",
    headers: {
      "x-csrftoken": getCsrfToken()
    }, credentials: 'include'
  });
}

async function resyncUserEntries(user) {
  return await fetch(API_URL + "resync-user-entries?user=" + user, {
    method: "POST",
    headers: {
      "x-csrftoken": getCsrfToken()
    }, credentials: 'include'
  });
}

async function importFromOris(overwrite) {
  const importProfileUrl = API_URL + "import-from-oris?overwrite=" + (overwrite ? '1' : '0');
  return await fetch(importProfileUrl, {
    method: "POST",
    headers: {
      "x-csrftoken": getCsrfToken()
    }, credentials: 'include'
  });
}

async function exportToOris(user) {
  const exportProfileUrl = user == null ? API_URL + "export-to-oris" : API_URL + "export-to-oris?user=" + user;
  return await fetch(exportProfileUrl, {
    method: "POST",
    headers: {
      "x-csrftoken": getCsrfToken()
    }, credentials: 'include'
  });
}

async function getPostDashboard(archived) {
  return await fetch(API_URL+"get-dashboard?" + new URLSearchParams({"archived": archived ? 1 : 0}), {credentials: 'include'});
}

async function getPost(postId) {
  return await fetch(API_URL+"get-post?" + new URLSearchParams({"id": postId}), {credentials: 'include'});
}

async function postPost(title, content, pinned, archived) {
  let formData = {
    "title": title,
    "content": content,
    "pinned": pinned,
    "archived": archived
  }
  return await fetch(API_URL+"edit-post", {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    credentials: 'include',
    body: JSON.stringify(formData)
  });
}

async function putPost(postId, title, content, pinned, archived) {
  let formData = {
    "title": title,
    "content": content,
    "pinned": pinned,
    "archived": archived
  }
  return await fetch(API_URL+"edit-post?" + new URLSearchParams({"id": postId}), {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    credentials: 'include',
    body: JSON.stringify(formData)
  });
}

async function getBugReports(archived) {
  return await fetch(API_URL+"bug-reports?" + new URLSearchParams({"archived": archived ? 1 : 0}), {credentials: 'include'});
}

async function postBugReport(title, detail) {
  let formData = {
    "title": title,
    "detail": detail,
  }
  return await fetch(API_URL+"bug-reports", {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    credentials: 'include',
    body: JSON.stringify(formData)
  });
}

async function putBugReport(id) {
  return await fetch(API_URL + "bug-reports?" + new URLSearchParams({
    "id": id
  }), {
    method: "PUT",
    headers: {
      "x-csrftoken": getCsrfToken()
    }, credentials: 'include'
  });
}

async function deleteBugReport(id) {
  return await fetch(API_URL + "bug-reports?" + new URLSearchParams({
    "id": id
  }), {
    method: "DELETE",
    headers: {
      "x-csrftoken": getCsrfToken()
    }, credentials: 'include'
  });
}

async function getWhitelistGroups(eventId) {
  return await fetch(API_URL+"edit-whitelist-groups?event="+eventId, {credentials: 'include'});
}

async function getWhitelistPeople(eventId) {
  return await fetch(API_URL+"edit-whitelist?event="+eventId, {credentials: 'include'});
}

async function getWhitelist(eventId) {
  return await fetch(API_URL+"get-whitelist?event="+eventId, {credentials: 'include'});
}

async function editWhitelistGroups(eventId, dor, sz, mz, de, ad, pd, ve){
  var data={
    "DO":dor,
    "SZ": sz,
    "MZ": mz,
    "DE": de,
    "AD": ad,
    "PD": pd,
    "VE": ve
  }
  return await fetch(API_URL+"edit-whitelist-groups?event="+eventId, {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    credentials: 'include',
    body: JSON.stringify(data)
  });
}

async function editWhitelistPeople(eventId, ids){
  let formData = {
    "ids": ids
  }
  return await fetch(API_URL+"edit-whitelist?event="+eventId, {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    credentials: 'include',
    body: JSON.stringify(formData)
  });
}

async function getEasterPair(eventId) {
  return await fetch(API_URL+"get-my-match?event="+eventId, {credentials: 'include'});
}

async function editMatches(eventId, matches){
  let formData = {
    "matches": matches
  }
  return await fetch(API_URL+"edit-matches?event="+eventId, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      "x-csrftoken": getCsrfToken()
    },
    credentials: 'include',
    body: JSON.stringify(formData)
  });
}

async function getMatches(eventId) {
  return await fetch(API_URL+"get-matches?event="+eventId, {credentials: 'include'});
}

export { postLogin, getCookie, getUserInfo, getHome, getCups, setCsrfToken, getFieldOptions,
  getServerInfo, getServerSettings, putServerSettings, getServerEmailQueue, getServerAsyncRuns, getServerActionLog,
  getEnrollInfo, getEnrollAnonymInfo, postEnroll, sendCampEnroll, sendCampAnonymEnroll, deleteEnroll, deleteEnrollAnonym,
  getEvent, getEventSync, getEditEvent, editEvent, getClubEnrolls, getClubEnrollsAdmin, createTraining, getEventApplyRights,
  createCamp, getCampsToCopy, putCampCopy, getQuestionsEdit, postPutQuestion, putMoveQuestion, deleteQuestion,
  getMirroring, putMirroring, deleteMirroring,
  getUserDetail, getUserStats, editUser, getUserOptionHistory, createUser, changeAccountLock, updateUsersCsv, getUsersToCreate,
  getEventRank, getMyEvents, getClubRanking, getServices, orderService, updateOrder, deleteOrder,
  getTransportInfo, editTransport, getMyVehicle, editMyTransport,
  getMembers, getMembersAdmin, getClubBilling, getClubBillingDetail, getClubStatistics,
  getUserRoles, postPutUserRole, getUserGroups, postPutUserGroup, getMembershipTypes, postPutMembershipType,
  getBillingYears, postNewBillingYear, putBillingYears,
  getBilling, getBillingSummary, getUserPayments, changeGrantLimit, getPaymentQRCode, getPeriods, stepPeriod,
  getTransportEditInfo, getClothes, getRights, getMyRights, editRights, getEditors, editEditors,
  exportAppliedMails, exportCsv, sendEmails, resendWelcomeEmail, resyncUserEntries, importFromOris, exportToOris, resetPassword, requestPasswordReset,
  getRequisitions, postRequisitionCsv, postRequisition, putRequisition, deleteRequisition,
  getRequisitionEvents, postRequisitionEvent, putRequisitionEvent, deleteRequisitionEvent, getForeignEvents, putExchangeRate,
  getPayments, getMembersForPayments, postPaymentCsv, putPayment, movePayments, deletePayment, getEditLinks, postPutLink, deleteLink,
  getPostDashboard, getPost, postPost, putPost,
  getBugReports, postBugReport, putBugReport, deleteBugReport,
  getWhitelist, getWhitelistGroups, getWhitelistPeople, editWhitelistGroups, editWhitelistPeople, getEasterPair, editMatches, getMatches};