import Table from "react-bootstrap/Table";
import React, { useState, useEffect } from 'react'
import {getFieldOptions, getMembers} from "../../dao";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown, faAngleUp, faChartColumn, faChartSimple, faMedal, faSignHanging, faRankingStar, faGears,
} from '@fortawesome/free-solid-svg-icons';
import Col from "react-bootstrap/Col";
import {isMobileDevice} from "../../helpers/Device";
import {handleErrorLoading, parseDate, setBrowserTabText} from "../../helpers/Functions";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import PageContainer from "../../layout/PageContainer";
import SearchBar from "../../components/form/SearchBar";
import {Badge} from "react-bootstrap";
import CondElem from "../../components/parts/CondElem";
import {labels} from "../../themeLabels";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import Loader from "../../components/overlays/Loader";

const Club = () => {
  const [data, setData] = useState({members: [], logged: {}});
  const [loading, setLoading] = useState(true);
  const [errorRemote, setErrorRemote] = useState("");
  const [fieldOptions, setFieldOptions] = useState({roles: [], memberships: [], groups: []});

  useEffect(() => {
    getFieldOptions()
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response))
          return {roles: [], memberships: [], groups: []}
        }
        return response.json();
      })
      .then((response) => {
        setFieldOptions(response);
      })
  }, []);

  useEffect(() => {
    setLoading(true);
    getMembers()
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response))
          return {members: [], logged: {}}
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (loading)
    return <Loader />;
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  setBrowserTabText('Klub')
  return (
    <PageContainer>
      <h1>Členové klubu</h1>
      <ClubCard/>
      <MemberTable />
    </PageContainer>
    );

  function ClubCard() {
    let unseenPostsBadge = <Badge style={{fontSize: "0.9em"}} pill bg="secondary">0</Badge>;
    const unseenPosts = localStorage.getItem('unseenPosts');
    if (unseenPosts != null) {
      if (unseenPosts > 0)
        unseenPostsBadge = <Badge style={{fontSize: "0.9rem"}} pill bg="warning">{unseenPosts}</Badge>
    }

    return (
      <Card className="mb-3">
        <Card.Body>
          <Row className="align-items-baseline">
            <Col md={6} sm={12}>
              <Link to={`/klub/nastenka`}><Button className="btn-primary mb-1 mt-1 me-3 tapis-95"><FontAwesomeIcon icon={faSignHanging} /> Nástěnka {unseenPostsBadge}</Button></Link>
              <Link to={`/klub/ranking`}><Button variant="outline-primary" className="mb-1 mt-1 tapis-95"><FontAwesomeIcon icon={faRankingStar} /> Klubový ranking</Button></Link>
            </Col>
            {data.logged?.perms?.view_users && <Col md={6} sm={12} className="text-end">
              <Link to={`/klub/statistiky`}><Button className="mb-1 mt-1 me-3 tapis-95" variant="outline-primary"><FontAwesomeIcon icon={faChartColumn} /> Statistiky</Button></Link>
              <Link to={`/admin`}><Button className="mb-1 mt-1 tapis-95"><FontAwesomeIcon icon={faGears} /> Administrace</Button></Link>
            </Col>}
          </Row>
        </Card.Body>
      </Card>
    )
  }

  function MemberTable() {
    const [filteredPeople, setFilteredPeople] = useState(data.members);

    function groupLabelByShortcut(shortcut) {
      if (shortcut == null)
        return ""
      return shortcut + " – " + fieldOptions.groups[shortcut]
    }

    function MemberDetail(elem) {
      const MemberDetailRow = ({label, value}) => {
        return (
          <Row className="m-0">
            <Col className="col-4">{label}</Col>
            <Col className="col-8">{value}</Col>
          </Row>
        )
      }
      return (<>
        <MemberDetailRow key="mail1" label="Email 1:" value={elem.data.mail1}/>
        {data.logged?.perms?.view_users && <>
          {elem.data.mail2 != null && <MemberDetailRow key="mail2" label="Email 2:" value={elem.data.mail2}/>}
          <MemberDetailRow key="phone" label="Telefon:" value={elem.data.phone}/>
          <MemberDetailRow key="address" label="Adresa:" value={elem.data.address}/>
          <MemberDetailRow key="birthdate" label="Datum nar.:" value={parseDate(elem.data.birthdate)}/>
        </>}
        <MemberDetailRow key="group" label="Skupina:" value={groupLabelByShortcut(elem.data.group)}/>
        <MemberDetailRow key="si" label="Čipy:" value={elem.data.si_cards}/>
      </>);
    }

    const TableRow = ({element}) => {
      const [showDetail, setShowDetail] = useState(false);

      function changeState() {
        setShowDetail(current => !current);
      }

      return (<>
        <tr>
          <td><CondElem href={`/ucet/${element.id}`} linked={data.logged?.perms?.view_users && labels.ClubPageTableLinks}>{element.reg_number}</CondElem></td>
          <td><CondElem href={`/ucet/${element.id}/zavody/`} linked={labels.ClubPageTableLinks}>{element.full_name}</CondElem></td>
          {!isMobileDevice() && <td>{element.mail1}</td>}
          <td align={"center"} className="pointing" onClick={changeState}><FontAwesomeIcon icon={showDetail ? faAngleUp : faAngleDown}/></td>
        </tr>
        {showDetail && <tr>
          <td colSpan="6" className="p-0 pt-1 pb-1"><MemberDetail data={element}/></td>
        </tr>}
      </>)
    }


    const rows = [];
    filteredPeople.forEach(element => {
      rows.push(<TableRow element={element} key={element.id}/>);
    });

    return (<>
      <SearchBar data={data} setFilteredPeople={setFilteredPeople} />
      <Table striped bordered responsive>
        <thead>
        <tr>
          <th><CondElem showIcon={data.logged?.perms?.view_users && labels.ClubPageTableLinks} suffixIcon={<FontAwesomeIcon icon={faChartSimple}/>}>Reg.</CondElem></th>
          <th><CondElem showIcon={labels.ClubPageTableLinks} suffixIcon={<FontAwesomeIcon icon={faMedal}/>}>Jméno</CondElem></th>
          {!isMobileDevice() && <th>Email primární</th>}
          <th></th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    </>)
  }
};


export default Club;