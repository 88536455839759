import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import {handleErrorLoading, handleErrorSubmit, setBrowserTabText} from "../../helpers/Functions";
import PageContainer from "../../layout/PageContainer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCircle, faGears, faMoneyBillTransfer, faPencil, faUser, faUserCog, faUsers} from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import FormField from "../../components/form/FormField";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import Table from "react-bootstrap/Table";
import React, {useEffect, useState} from "react";
import {getUserRoles, postPutUserRole} from "../../dao";
import FormCheckbox from "../../components/form/FormCheckbox";


const UserRoles = () => {
  const [data, setData] = useState({});
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const [roleToChange, setRoleToChange] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [short, setShort] = useState("");
  const [applyAll, setApplyAll] = useState(false);
  const [editEvents, setEditEvents] = useState(false);
  const [viewUsers, setViewUsers] = useState(false);
  const [editUsers, setEditUsers] = useState(false);
  const [useBilling, setUseBilling] = useState(false);
  const [administrate, setAdministrate] = useState(false);


  useEffect(() => {
    getUserRoles()
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {};
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = () => {
    setShowModal(false);
    const formData = {
      "title" : title,
      "short": short,
      "apply_all": applyAll,
      "edit_events": editEvents,
      "view_users": viewUsers,
      "edit_users": editUsers,
      "use_billing": useBilling,
      "administrate": administrate,
    };
    postPutUserRole(roleToChange, formData)
      .then((res) => {
        if (res.ok) {
          setSuccess(roleToChange ? "Upraveno" : "Přidáno");
          res.json().then((response) => setData(response));
        } else {
          setError(handleErrorSubmit(res, "Operace se nezdařila"));
        }
      })
  }

  if (loading)
    return <Loader />;
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  setBrowserTabText('Správa uživatelských rolí');
  return (
    <PageContainer>
      <h1>Správa uživatelských rolí</h1>
      <Row>
        <Col className="mb-3 text-end">
          <Button onClick={() => {
            setRoleToChange(null);
            setTitle("");
            setShort("");
            setApplyAll(false);
            setEditEvents(false);
            setViewUsers(false);
            setEditUsers(false);
            setUseBilling(false);
            setAdministrate(false);
            setShowModal(true);
          }}>+ Přidat</Button>
        </Col>
      </Row>
      <UserRolesTable/>
      <p><FontAwesomeIcon icon={faCircle} color="orange"/> - výchozí role</p>
      <Modal show={showModal} onHide={()=>setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{roleToChange ? "Upravit" : "Přidat"} roli</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormField label={"Zkratka"} value={short} setValue={setShort} valueLimit={2} isInvalid={short.length === 0} digits="none" inline={true} controlId={"short"} />
            <FormField label={"Název"} value={title} setValue={setTitle} valueLimit={32} isInvalid={title.length === 0} digits="none" inline={true} controlId={"title"} />
            <FormCheckbox label={"Přihlašování všichni"} value={applyAll} setValue={setApplyAll} controlId={"applyAll"}/>
            <FormCheckbox label={"Úprava akcí"} value={editEvents} setValue={setEditEvents} controlId={"editEvents"}/>
            <FormCheckbox label={"Data uživatelů"} value={viewUsers} setValue={setViewUsers} controlId={"viewUsers"}/>
            <FormCheckbox label={"Úprava uživatelů"} value={editUsers} setValue={setEditUsers} controlId={"editUsers"}/>
            <FormCheckbox label={"Účetnictví"} value={useBilling} setValue={setUseBilling} controlId={"useBilling"}/>
            <FormCheckbox label={"Administrace"} value={administrate} setValue={setAdministrate} controlId={"administrate"}/>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Zavřít
          </Button>
          <Button variant="primary" disabled={title.length === 0 || short.length === 0} onClick={handleSubmit}>
            {roleToChange ? "Upravit" : "Přidat"}
          </Button>
        </Modal.Footer>
      </Modal>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={()=>setSuccess("")}/>
    </PageContainer>
  );

  function UserRolesTable() {
    const rows = [];
    for (const i in data?.roles) {
      const role = data.roles[i];
      rows.push(<tr key={i}>
        <td>{role.short}</td>
        <td>{role.title} {role.default && <FontAwesomeIcon icon={faCircle} color="orange"/>}</td>
        <td className="align-center">{role.profiles}</td>
        <td className="align-center">{role.apply_all && <FontAwesomeIcon icon={faCheck} className="color-primary"/>}</td>
        <td className="align-center">{role.edit_events && <FontAwesomeIcon icon={faCheck} className="color-primary"/>}</td>
        <td className="align-center">{role.view_users && <FontAwesomeIcon icon={faCheck} className="color-primary"/>}</td>
        <td className="align-center">{role.edit_users && <FontAwesomeIcon icon={faCheck} className="color-primary"/>}</td>
        <td className="align-center">{role.use_billing && <FontAwesomeIcon icon={faCheck} className="color-primary"/>}</td>
        <td className="align-center">{role.administrate && <FontAwesomeIcon icon={faCheck} className="color-primary"/>}</td>
        <td className="td-w-icon"><FontAwesomeIcon icon={faPencil} className="link-like" onClick={() => {
          setRoleToChange(role.id);
          setTitle(role.title);
          setShort(role.short);
          setApplyAll(role.apply_all);
          setEditEvents(role.edit_events);
          setViewUsers(role.view_users);
          setEditUsers(role.edit_users);
          setUseBilling(role.use_billing);
          setAdministrate(role.administrate);
          setShowModal(true);
        }}/></td>
      </tr>);
    }

    return (
      <Table striped bordered hover responsive>
        <thead>
        <tr>
          <th></th>
          <th>Název</th>
          <th>Poč.</th>
          <th className="align-center"><FontAwesomeIcon icon={faUsers}/></th>
          <th className="align-center"><FontAwesomeIcon icon={faPencil}/></th>
          <th className="align-center"><FontAwesomeIcon icon={faUser}/></th>
          <th className="align-center"><FontAwesomeIcon icon={faUserCog}/></th>
          <th className="align-center"><FontAwesomeIcon icon={faMoneyBillTransfer}/></th>
          <th className="align-center"><FontAwesomeIcon icon={faGears}/></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    );
  }
}

export default UserRoles;