import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getUserStats} from "../../dao";
import {YearOptions, handleErrorLoading, setBrowserTabText, parseDate} from "../../helpers/Functions";
import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartSimple, faClockRotateLeft, faCoins, faGavel, faGear, faMedal, faPeopleArrows} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import {Badge} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import FormSelectArray from "../../components/form/FormSelectArray";


const UserStats = () => {
  const { user } = useParams();
  const [errorRemote, setErrorRemote] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [yearShown, setYearShown] = useState((new Date()).getFullYear().toString());

  useEffect(() => {
    setLoading(true);
    getUserStats(user, yearShown)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [user, yearShown]);

  if (loading)
    return <Loader loading={loading} />
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />

  setBrowserTabText(user == null ? 'Statistiky' : 'Statistiky | ' + data?.profile?.full_name);
  const urlRoot = user == null ? "/ucet/" : "/ucet/" + user + "/";
  return (
    <PageContainer>
      {user == null ? <h1><FontAwesomeIcon icon={faChartSimple} /> Statistiky</h1> : <h1><FontAwesomeIcon icon={faChartSimple} /> Statistiky: {data?.profile?.full_name}</h1>}
      {data.logged?.perms?.administrate && <AdminCard />}
      <InfoCard />
      <h4>Účasti na akcích</h4>
      <YearsTable />
      <br/>
      <DNSContent />
      <RankingSection />
    </PageContainer>
  )

  function AdminCard() {
    return (
      <Row>
        <Col>
          <Card className="mb-3">
            <Card.Body>
              <Row className="align-items-center">
                <Col xs="auto">
                  <Button className="mb-1 mt-1" href={urlRoot + "zavody"}><FontAwesomeIcon icon={faMedal} /> Závody</Button>
                </Col>
                <Col xs="auto">
                  <Button className="mb-1 mt-1" variant="secondary" href={urlRoot + "nastaveni"}><FontAwesomeIcon icon={faGear} /> Nastavení</Button>
                </Col>
                <Col xs="auto">
                  <Button className="mb-1 mt-1" variant="outline-secondary" href={urlRoot + "opravneni"}><FontAwesomeIcon icon={faPeopleArrows} /> Oprávnění</Button>
                </Col>
                <Col xs="auto">
                  <Button className="mb-1 mt-1" variant="secondary" href={urlRoot + "prava"}><FontAwesomeIcon icon={faGavel} /> Práva</Button>
                </Col>
                <Col xs="auto">
                  <Button className="mb-1 mt-1" href={urlRoot + "vyuctovani"}><FontAwesomeIcon icon={faCoins} /> Vyúčtování</Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }

  function InfoCard() {
    return (
      <Row>
        <Col>
          <Card className="mb-4 mt-2">
            <Card.Body>
              <Row className="align-items-center">
                <Col md className="mb-1">
                  Aktuální rok: <b>{new Date().getFullYear()}</b>
                </Col>
                <Col md>
                  {data?.dns_jokers != null &&
                    <Row className="mb-1">
                      <Col>
                        Zbývající omluvenky: <Badge style={{fontSize: "12px"}} pill bg={data?.dns_jokers <= 1 ? (data?.dns_jokers === 0 ? "danger" : "warning") : "success"}>{data?.dns_jokers}</Badge>
                      </Col>
                    </Row>
                  }
                </Col>
                <Col md={3} className="text-end">
                  <Button className="mb-1 mt-1" href={urlRoot + "clenstvi"}><FontAwesomeIcon icon={faClockRotateLeft}/> Historie členství</Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }

  function YearsTable() {
    if (data?.years === undefined)
      return;
    const rows = [];
    for (let year in data?.years) {
      const element = data?.years[year];
      rows.push(
        <tr key={year}>
          <td>{year}</td>
          <td className="td-sep"></td>
          <td>{element.races}</td>
          <td>{element.presence != null && element.presence + " %"}</td>
          <td>{element.not_started}</td>
          <td className="td-sep"></td>
          <td>{element.club}</td>
        </tr>);
    }

    return (
      <Table striped bordered hover responsive>
        <thead>
        <tr>
          <th>Rok</th>
          <th className="td-sep"></th>
          <th>Závody</th>
          <th>Účast</th>
          <th>DNS</th>
          <th className="td-sep"></th>
          <th>Klub</th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </Table>
    );
  }

  function DNSContent() {
    if (data?.dns_events === undefined)
      return;
    const rows = [];
    for (let event in data?.dns_events) {
      const element = data?.dns_events[event];
      rows.push(
        <tr key={event}>
          <td>#{element.dns_idx}</td>
          <td>{parseDate(element.date)}</td>
          <td><Link to={`/akce/${element.id}`} className="no-decoration">{element.title}</Link></td>
        </tr>);
    }

    return (
      <>
        <Row className="align-items-baseline mb-2">
          <Col xs="auto"><h4>DNS akce v roce</h4></Col>
          <Col className="ps-4">
            <FormSelectArray label={""} inline={true} size="sm" value={yearShown} setValue={setYearShown} options={YearOptions()} className="mb-1" controlId={"yearShown"} />            </Col>
        </Row>
        {rows.length > 0 ?
          <Table striped bordered hover responsive>
            <thead>
            <tr>
              <th></th>
              <th>Dat.</th>
              <th>Název</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
          </Table> : <p><i>Žádné DNS akce (akce s nevyužitou přihláškou) ve vybraném roce.</i></p>
        }
      </>
    )
  }

  function RankingSection() {
    const rows = [];
    for (let r in data?.ranking) {
      const element = data?.ranking[r];
      rows.push(
        <tr key={r}>
          <td>OB</td>
          <td>{parseDate(element.date)}</td>
          <td>{element.place}</td>
          <td>{element.points}</td>
          <td>{element.coef}</td>
        </tr>);
    }

    if (rows.length === 0)
      return
    return (
      <>
        <hr/>
        <h4>Rankingová historie</h4>
        <Table striped bordered hover responsive>
          <thead>
          <tr>
            <th></th>
            <th>Dat.</th>
            <th>Místo</th>
            <th>Body</th>
            <th>Koef</th>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </Table>
      </>
    )
  }
}

export default UserStats;