import Form from "react-bootstrap/Form";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";


const FormField = ({label, value, setValue, valueLimit=100, disabled=false, readOnly=false, inline=false, digits, type="text", help="", isInvalid=false, isValid=false, minNumberVal=-1, maxNumberVal=-1, placeholder="", autoComplete="", className="mb-3", controlId}) => {
  const Digits = () => {
    if (type !== "text" || value === undefined)
      return <></>;
    switch (digits) {
      case "slash":
        return <> ({value.length}/{valueLimit})</>;
      case "none":
        return <></>;
      case "remaining":
        return <> ({valueLimit - value.length})</>;
      default:
        const diff = valueLimit - value.length;
        return (diff > 12 ? <></> : <> ({diff})</>);
    }
  }

  const HelpIcon = () => {
    if (help === "")
      return <></>;
    return <FontAwesomeIcon icon={faQuestionCircle} title={help} />;
  }

  const ControlField = () => {
    return (<Form.Control style={{fontSize: "0.9rem"}} type={fieldType} value={value} disabled={disabled} readOnly={readOnly} isInvalid={isInvalid} isValid={isValid} placeholder={placeholder} autoComplete={autoComplete} onChange={(e) => {
      const inputValue = e.target.value;

      switch (type) {
        case "decimal":
          if (!inputValue || inputValue.match(/^\d{1,}(\.\d{0,4})?$/)) {
            setValue(inputValue);
          }
          break;
        case "duration":
          if (!inputValue || inputValue.match(/^\d{1,3}(:\d{0,2})?$/)) {
            setValue(inputValue);
          }
          break;
        case "slashed":
          const slashPosition = valueLimit - 5;
          if (inputValue.length <= valueLimit) {
            if (inputValue.length > value.length) {
              const slashedValue = (inputValue.length === slashPosition && inputValue.indexOf('/') === -1) ? inputValue + "/" : inputValue;
              setValue(slashedValue);
            } else {
              setValue(inputValue);
            }
          }
          break;
        case "text":
        case "number":
          if (type === "number" && (minNumberVal !== -1 || maxNumberVal !== -1)) {
            const numberValue = Number(inputValue);
            if (minNumberVal <= numberValue && numberValue <= maxNumberVal)
              setValue(numberValue);
          } else if (inputValue.length <= valueLimit) {
            if (type === "number")
              setValue(Number(inputValue));
            setValue(inputValue);
          }
          break;
        default:
          setValue(inputValue);
          break;
      }
    }} />)
  }

  let fieldType = type;
  if (type === "decimal" || type === "slashed")
    fieldType = "text";

  if (inline) {
    return (
      <Form.Group className={className} controlId={controlId}>
        <div style={{display: "flex"}}>
          {label !== "" && <Form.Label className="me-2 pt-1 pb-1 mb-0">{label}<Digits/>: <HelpIcon/></Form.Label>}
          {ControlField()}
        </div>
      </Form.Group>
    )
  }

  return (
    <Form.Group className={className} controlId={controlId}>
      {label !== "" && <Form.Label>{label}<Digits/>: <HelpIcon/></Form.Label>}
      {ControlField()}
    </Form.Group>
  )
}

export default FormField;