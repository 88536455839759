import PageContainer from "../../layout/PageContainer";
import {handleErrorSubmit, setBrowserTabText} from "../../helpers/Functions";
import {postBugReport} from "../../dao";
import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import {isMobileDevice} from "../../helpers/Device";
import {Link, useNavigate} from "react-router-dom";
import useUserData from "../../helpers/Auth";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBug} from "@fortawesome/free-solid-svg-icons";
import FormField from "../../components/form/FormField";


const BugReport = () => {
  const { userData, setUserData } = useUserData();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [title, setTitle] = useState("");
  const [detail, setDetail] = useState("");
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const titleLimit = 120;
  const detailLimit = 1500;

  useEffect(() => {
    const scrollingElement = (document.scrollingElement || document.body);
    scrollingElement.scrollTop = 0;
  }, []);

  let handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    postBugReport(title, detail)
      .then((response) => {
        if(response.ok){
          setSuccess("Díky za nahlášení!");
        }else{
          setError(handleErrorSubmit(response, "Nezdařilo se vytvořit"));
        }
        setProcessing(false);
      })
  };

  const handleDetailChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= detailLimit) {
      setDetail(inputValue);
    }
  };

  setBrowserTabText('Nahlásit chybu');
  return (
    <PageContainer>
      <Row>
        <Col><h1>Nahlásit chybu nebo podnět</h1></Col>
        {userData?.administrate && <Col className="text-end"><Link to="/admin/bugy"><Button variant="secondary"><FontAwesomeIcon icon={faBug}/> Nahlášené chyby</Button></Link></Col>}
      </Row>
      <br/>
      <Form onSubmit={handleSubmit}>
        <FormField label={"Název chyby nebo podnětu"} value={title} setValue={setTitle} valueLimit={titleLimit} digits={"slash"} controlId={"title"} />

        <Form.Group className="mb-3" controlId="detail">
          <Form.Label>Detail ({detail.length}/{detailLimit}):</Form.Label>
          <Form.Control type="text" as="textarea" rows={isMobileDevice() ? 9 : 6} value={detail} onChange={handleDetailChange} />
        </Form.Group>
        <p>U chyby popiš prosím co nejdůkladněji, kde se systém chová neočekávaně, a co jsi udělal/a (např. hodnoty vyplněné do polí formuláře).</p>
        <Row className="mt-3">
          <Col className="text-end">
            <Button variant="primary" type="submit" disabled={processing}>Odeslat</Button>
          </Col>
        </Row>
      </Form>
      <ErrorAlert error={error} handleClose={()=> setError("")}/>
      <SuccessAlert title={success} handleClose={()=> navigate("/")}/>
    </PageContainer>
  )
}

export default BugReport;