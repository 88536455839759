import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight} from "@fortawesome/free-solid-svg-icons";
import React from "react";


const PageSwitch = ({page, setPage, rowsPerPage, rowCount}) => {
  return (
    <Row className="mb-3">
      <Col lg={3} md={2} sm={1} xs={0}/>
      <Col className="text-center">
        <Button variant="outline-primary" onClick={() => {setPage(1)}} disabled={page <= 1}><FontAwesomeIcon icon={faAnglesLeft}/></Button>
        <Button className="ms-1 me-1" onClick={() => {if (page > 1) setPage(page - 1)}} disabled={page <= 1}><FontAwesomeIcon icon={faAngleLeft}/></Button>
        <Button variant="secondary">{rowCount < rowsPerPage ? 1 : page}</Button>
        <Button className="ms-1 me-1" onClick={() => {if (page * rowsPerPage < rowCount) setPage(page + 1)}} disabled={page * rowsPerPage >= rowCount}><FontAwesomeIcon icon={faAngleRight}/></Button>
        <Button variant="outline-primary" onClick={() => {setPage(Math.ceil(rowCount / rowsPerPage))}} disabled={page * rowsPerPage >= rowCount}><FontAwesomeIcon icon={faAnglesRight}/></Button>
      </Col>
      <Col lg={3} md={2} sm={1} xs={0}/>
    </Row>
  )
}

export default PageSwitch;