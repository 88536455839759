import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AutoFormat from "../visual/AutoFormat";
import React from "react";
import WarningCard from "../overlays/WarningCard";
import {labels} from "../../themeLabels";
import Button from "react-bootstrap/Button";
import VehicleIcon from "../visual/VehicleIcon";


const VehicleList = ({eventId, vehicles, user=undefined, editCrew=undefined, processing}) => {
  const VehicleTitle = ({vehicle}) => {
    const myTransportLink = "/akce/" + eventId + "/doprava"
    if (vehicle.my_vehicle)
      return (<b><Link to={myTransportLink}><VehicleIcon title={vehicle.title} /></Link> <Link to={myTransportLink}>{vehicle.title}</Link></b>);
    return (<b><VehicleIcon title={vehicle.title} /> {vehicle.title}</b>);
  }

  const Crew = ({people}) => {
    const crew = [];
    for (const i in people) {
      const person = people[i];
      if(person.full_name != null){
        crew.push(
          <Card key={person.id} className="mt-2">
            <p className="m-1 ms-2 me-2">{person.full_name}</p>
          </Card>
        );
      }else{
        crew.push(<Card className="mt-2" style={{borderStyle: "dotted"}}>
          <i className="m-1">Volno</i>
        </Card>);
      }
    }
    return crew;
  }

  const res = [];
  for (const i in vehicles) {
    const vehicle = vehicles[i];
    const freeSpaces = vehicle.capacity - vehicle.crew.length;
    res.push(
      <Col xs={12} sm={6} md={4} lg={3} xxl={2} className="ps-1 pe-1" key={vehicle.id}>
        <Card className="mb-2">
          <Card.Body>
            <p className="mb-1"><VehicleTitle vehicle={vehicle}/></p>
            {vehicle.driver != null && <Card key={vehicle.driver.id} className="mt-2 mb-2">
              <p className="m-1 ms-2 me-2">{vehicle.driver?.full_name}</p>
            </Card>}
            <div>
              <FontAwesomeIcon icon={faCircleInfo} color="gray"/> <AutoFormat text={vehicle.info}/>
            </div>
            <p className="mb-1"></p>
            <Crew people={vehicle.crew}/>
            {(labels.MyTransportSelf && user?.id) && <div>
              {user?.vehicle ?
                (vehicle.id === user.vehicle && vehicle.driver?.id !== user?.id) && <>
                  <hr/>
                  <Button disabled={processing} onClick={() => {
                    if (editCrew) editCrew(vehicle.id, user?.id);
                  }}>Odebrat se</Button>
                </> :
                <>
                  <hr/>
                  <p className="mb-1">Volná místa: {freeSpaces}</p>
                  {freeSpaces > 0 && <Button disabled={processing} onClick={() => {
                    if (editCrew) editCrew(vehicle.id, user?.id);
                  }}>Přidat se</Button>}
                </>
              }
            </div>}
          </Card.Body>
        </Card>
      </Col>
    );
  }
  if (res.length < 1)
    return <WarningCard text="Žádná vozidla"/>;
  return res;
}

export default VehicleList;