import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getPost} from "../../dao";
import {handleErrorLoading, parseDateTime, setBrowserTabText} from "../../helpers/Functions";
import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import PageHeading from "../../layout/PageHeading";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import Card from "react-bootstrap/Card";

const Post = () => {
  const { postId } = useParams();
  const [post, setPost] = useState({});
  const [errorRemote, setErrorRemote] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPost(postId)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json()
      })
      .then((response) => {
        setPost(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const CreatorEditorText = ({person, label}) => {
    const labelText = label + ": ";
    if (person == null)
      return "";
    return <i>{labelText} {person.reg_number} – {person.full_name}</i>;
  }

  if (loading)
    return <Loader />;
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;

  setBrowserTabText('Příspěvek: ' + post?.title);
  return (
    <PageContainer>
      <Card className="mt-3">
        <Card.Body>
          <PageHeading heading={post?.title} label={"Nástěnka"} link={"/klub/nastenka"}/>
          <div className="post-dashboard" dangerouslySetInnerHTML={{__html: post.content}} style={{minHeight: "400px"}}/>
          <hr/>
          <Row className="align-items-end mt-1">
            <Col md className="mb-1">
              <CreatorEditorText person={post.created_by} label={"Vytvořil/a"}/><br/>
              <CreatorEditorText person={post.edited_by} label={"Upravil/a"}/>
            </Col>
            <Col md className="text-end mb-1">
              {post.can_edit &&
                <p className="mb-0"><a href={`/klub/prispevek/${post.id}/upravit`}><FontAwesomeIcon icon={faPencil} size="lg" className="link-like"/></a>
                </p>}
              <p className="mb-0"><i>Zobrazení: {post.view_count}</i></p>
              <p className="mb-0"><i>Publikováno: {parseDateTime(post.published)}</i></p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </PageContainer>
  )
}

export default Post;