import iconOris from "../../static/iconoris.png";
import logoOris from "../../static/logooris.png";
import iconOrisRed from "../../static/iconoris_red.png";
import logoOrisRed from "../../static/logooris_red.png";
import iconTAP from "../../static/iconTAP.png";
import iconPGP from "../../static/iconPGP.png";
import {Theme} from "../../helpers/Constants";

function selectLogoOris(small=false) {
  switch (Theme) {
    case "PGP":
      return (small ? iconOris : logoOris);
    default:
      return (small ? iconOris : logoOris);
  }
}

function selectIconClub() {
  switch (Theme) {
    case "PGP":
      return iconPGP;
    default:
      return iconTAP;
  }
}

export {selectLogoOris, selectIconClub}
