import React, {useEffect, useState} from "react";
import {getServerSettings, putServerSettings} from "../../dao";
import {handleErrorLoading, handleErrorSubmit, setBrowserTabText} from "../../helpers/Functions";
import Loader from "../../components/overlays/Loader";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import PageContainer from "../../layout/PageContainer";
import Col from "react-bootstrap/Col";
import FormField from "../../components/form/FormField";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCodeBranch, faEnvelopesBulk, faTableList} from "@fortawesome/free-solid-svg-icons";
import FormCheckbox from "../../components/form/FormCheckbox";


const ServerSettings = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [errorRemote, setErrorRemote] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);

  const [message, setMessage] = useState("");
  const [info, setInfo] = useState("");
  const [sendEmails, setSendEmails] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  function loadData() {
    setLoading(true);
    getServerSettings()
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return [];
        }
        return response.json();
      })
      .then((response) => {
        setData(response);
        setMessage(response.message);
        setInfo(response?.info);
        setSendEmails(response.send_emails);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    putServerSettings(message, info, sendEmails)
      .then((response) => {
        if (response.ok) {
          setSuccess("Nastavení změněno");
          loadData();
        } else {
          setError(handleErrorSubmit(response, "Nepodařilo se změnit"));
        }
      })
  };

  if (loading)
    return <Loader />;
  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote} />;

  setBrowserTabText('Stav serveru');
  return (
    <PageContainer>
      <h1>Stav serveru</h1>
      <Row className="mb-1">
        <Col>Akce dnes:</Col>
        <Col>
          <FormField label={""} type={"text"} value={data.actions_today} readOnly={true} controlId={"actionsToday"}/>
        </Col>
        <Col className="text-end">
          <Link to="log"><Button variant="outline-secondary"><FontAwesomeIcon icon={faTableList}/> ActionLog</Button></Link>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col>Fronta emailů:</Col>
        <Col>
          <FormField label={""} type={"text"} value={data.emails_waiting} readOnly={true} controlId={"emailQueue"}/>
        </Col>
        <Col className="text-end">
          <Link to="email-queue"><Button variant="outline-secondary"><FontAwesomeIcon icon={faEnvelopesBulk}/> EmailQueue</Button></Link>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col>Běžící procesy:</Col>
        <Col>
          <FormField label={""} type={"text"} value={data.async_runs_running} readOnly={true} controlId={"asyncRuns"}/>
        </Col>
        <Col className="text-end">
          <Link to="async-runs"><Button variant="outline-secondary"><FontAwesomeIcon icon={faCodeBranch}/> AsyncRuns</Button></Link>
        </Col>
      </Row>
      <hr/>
      <Form onSubmit={handleSubmit}>
        <Row className="align-items-end">
          <Col xs={12} sm>
            <FormField label={"Varianta"} type={"text"} value={data?.theme} readOnly={true} inline={true} controlId={"theme"}/>
            <FormField label={"Verze"} type={"text"} value={data.version} readOnly={true} inline={true} controlId={"version"}/>
          </Col>
          <Col xs={6} sm>
            <FormCheckbox label={"Development"} tooltip={"Development chybové výpisy"} value={data?.development} condensed={true} disabled={true} controlId={"dev"}/>
            <FormCheckbox label={"Demo"} tooltip={"Demo bez propojení do externích služeb (Oris, maily apod.)"} value={data?.demo} condensed={true} disabled={true} controlId={"demo"}/>
            <FormCheckbox label={"Deployed"} tooltip={"Nasazeno, zapnuty sychronizace na pozadí"} value={data?.deployed} disabled={true} controlId={"dep"}/>
          </Col>
          <Col xs={6} sm>
            <FormCheckbox label={"Odesílání emailů"} tooltip={"Emaily z fronty odesílány / Emaily drženy ve frontě"} value={sendEmails} setValue={setSendEmails} controlId={"emails"}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormField label={"Varovná zpráva"} value={message} setValue={setMessage} valueLimit={128} digits={"slash"} controlId={"message"}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormField label={"Info zpráva"} value={info} setValue={setInfo} valueLimit={128} digits={"slash"} controlId={"info"}/>
          </Col>
        </Row>
        <Row>
          <Col className="text-end">
            <Button className="mb-2" variant="primary" type="submit">Uložit změny</Button>
          </Col>
        </Row>
      </Form>
      <ErrorAlert error={error} handleClose={() => setError("")}/>
      <SuccessAlert title={success} handleClose={() => setSuccess("")}/>
    </PageContainer>
  )
}

export default ServerSettings;