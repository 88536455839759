import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBusSimple, faCar, faTrain} from "@fortawesome/free-solid-svg-icons";


const VehicleIcon = ({title=""}) => {
  const titleUpper = title.toUpperCase();
  if (titleUpper.indexOf("VLAK") !== -1)
    return <FontAwesomeIcon icon={faTrain}/>
  if (titleUpper.indexOf("AUTOBUS") !== -1)
    return <FontAwesomeIcon icon={faBusSimple}/>
  return <FontAwesomeIcon icon={faCar}/>
}

export default VehicleIcon;