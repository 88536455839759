const themeConfig = {
  TAP: {
    Theme: 'TAP',
    ApiUrl: 'https://api.tapis.tretra.org/',
    SystemStartYear: 2024,
    SystemShortName: 'Tapis',
    SystemDescription: 'Tretrácký informační systém',
    AdminEmail: 'tapis@tretra.org',
    HomeClub: "TAP",
    HomeClubName: "KOB Tretra Praha",
    HomeClubBankAccount: "2802097915/2010",
    TabTitle: 'Tapis – Tretrácký informační systém',
    MetaDescription: 'Informační systém KOB Tretra Praha',

    homeSection: 'Domů',
    clubSection: 'Klub',
    cupsSection: 'Žebříčky',
    myRacesSection: 'Mé závody',

    entryPageLocalCreate: 'Přihlásit se',
    entryPageRemoteCreate: 'Přihlásit se',
    entryPagePreliminaryCreate: 'Přihlásit se',
    entryPageEdit: 'Upravit přihlášku',
    entryOthersLocal: 'Přihlásit ostatní',
    entryOthersRemote: 'Přihlásit ostatní',

    servicesPage: 'Služby',

    clubRankingPage: 'Klubový ranking',

    ClubPageTableLinks: '1',
    ClubAdminPageTableLinks: '1',
    ClubAdminSpecialSyncToOris: '1',
    MyTransportSelf: '',

    usernamePlaceholder: 'Tretrak',
  },
  PGP: {
    Theme: 'PGP',
    ApiUrl: 'https://api.pragis.sk-praga.cz/',
    SystemStartYear: 2024,
    SystemShortName: 'PragIS',
    SystemDescription: 'Pragovácký informační systém',
    AdminEmail: 'syon@centrum.cz',
    HomeClub: "PGP",
    HomeClubName: "SK Praga",
    HomeClubBankAccount: "???",
    TabTitle: 'PragIS – Pragovácký informační systém',
    MetaDescription: 'Přihlášení do PragISu',

    homeSection: 'Kalendář akcí',
    clubSection: 'Seznam členů',
    cupsSection: '',
    myRacesSection: '',

    entryPageLocalCreate: 'Přihlásit se',
    entryPageRemoteCreate: '',
    entryPagePreliminaryCreate: '',
    entryPageEdit: 'Přihláška dopravy a ubytování',
    entryOthersLocal: 'Přihlásit ostatní',
    entryOthersRemote: '',

    servicesPage: '',

    clubRankingPage: 'Klubový ranking',

    ClubPageTableLinks: '',
    ClubAdminPageTableLinks: '',
    ClubAdminSpecialSyncToOris: '',
    MyTransportSelf: '1',

    usernamePlaceholder: 'PGP0000',
  },
};

const getSubdomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');
  if (parts.length > 2) {
    return parts[0]; // Třetí řád domény
  }
  return null;
};

const getTheme = () => {
  switch (getSubdomain()) {
    case 'tapis':
      return 'TAP'
    case 'pragis':
      return 'PGP'
    default:
      return 'TAP'
  }
}

const themeVariant = getTheme();
const currentTheme = themeConfig[themeVariant].Theme;
const ApiUrl = (process.env.NODE_ENV === 'production' ? themeConfig[themeVariant].ApiUrl : 'http://localhost:8000/');
export {currentTheme, ApiUrl};
export const labels = themeConfig[themeVariant];
