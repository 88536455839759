
const getColor = ({ value, border }) => {
    if (value < 0)
        return `rgb(64, 128, 64)`;
    if (value === 0)
        return `rgb(0, 0, 0)`;
    const ratio = value / border;

    if (ratio < 0.2)
        return `rgb(255, 215, 0)`
    if (ratio < 0.4)
        return `rgb(255, 140, 0)`
    if (ratio < 0.8)
        return `rgb(255, 0, 0)`
    return `rgb(139, 0, 0)`;

    // color scale version
    //
    // const red = Math.min(255, Math.round(255 - (value - border) * 255 / border));
    // const green = Math.min(255, Math.round((border - value) * 255 / border));
    // const blue = Math.min(255, Math.round((value - border) * 255 / border / 2));
    //
    // return `rgb(${red}, ${green}, ${blue})`;
};
const textStyle = ({ value, border }) => {return value === 0 ? {} : {color: getColor({value, border}),}};

const ColorChangingText = ({ value, border }) => {
    return (
        <div style={textStyle({ value, border })}>
            {value}
        </div>
    );
};

export default ColorChangingText;